var
    jQuery_VERSION = jQuery().jquery,
    UI_MODES = this.UI_MODES || {},
    JS_FILE_POSTFIX_EXT = (typeof USE_COMPRESSED_ASSET !== 'undefined' ? '.min' : '') + '.js',
    DEFAULT_LANGUAGE = 'en',
    USER_LANGUAGE = DEFAULT_LANGUAGE,
    USER_LOCATION = 'us',
    USER_SUPPORTED_TRANSLATIONS_CODES = {
        'en': true,
        'en-ca': true,
        'en-gb': true,
        'de': true,
        'es': true,
        'fr': true,
        'fr-ca': true,
        'pt': true
    },
	DATE_FORMAT = ({
          'default': 'mm/dd/yyyy',
          'global': 'dd/mm/yyyy',
          'defined': window.DATE_FORMAT
        })[
            window.IS_GLOBAL && (window.IS_GLOBAL === 'true' || window.IS_GLOBAL === true)
              ? 'global'
              : window.DATE_FORMAT
                  ? 'defined'
                  : 'default'
          ],
    GA_BROCKER_TIMEOUT = 2000,
    BASE_64_SINGLE_PIXEL_CODE = 'data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==',
    VITALITYPORTAL_SESSION_UUID_META_NAME = 'session_uuid',
    VITALITYPORTAL_SESSION_UUID = '';


(function(){

    $(document).on('dragstart', function(e) {
        if (e.target.nodeName.toLowerCase() === 'img') {
            return false;
        }
    });

    if (typeof Lockr === 'object') {
        Lockr.prefix = 'vitality_'
    }

    VITALITYPORTAL_SESSION_UUID = $('meta[name=' + VITALITYPORTAL_SESSION_UUID_META_NAME + ']').attr('content');

    if (! $.cookie) {
        return;
    }

    var
        lang = ($.cookie('MNCPORTAL_LANGUAGE') || '').toLowerCase(),
        loc = ($.cookie('MNCPORTAL_LOCATION') || '').toLowerCase();

    if (lang && (lang in USER_SUPPORTED_TRANSLATIONS_CODES) && USER_SUPPORTED_TRANSLATIONS_CODES[lang]) {
        USER_LANGUAGE = lang;
    }

    if (loc) {
        USER_LOCATION = loc;
    }

    if (lang === 'en') {
        if (USER_LOCATION === 'ca') {
            USER_LANGUAGE = 'en-ca';
        }
        else if (USER_LOCATION === 'gb') {
            USER_LANGUAGE = 'en-gb';
        }
    }

    if (lang === 'fr' && USER_LOCATION === 'ca') {
        USER_LANGUAGE = 'fr-ca';
    }

    UI_MODES.DIRECT_LOCALES_LOAD
        ?
            (function(){
                if (USER_LANGUAGE !== DEFAULT_LANGUAGE) {
                    document.write('<scrip' + 't src="' + STATICS_LOCATION + '/js/libs/plugins/moment.locales/' + USER_LANGUAGE + (USE_COMPRESSED_ASSETS ? '.min' : '') + '.js"></scrip' + 't>');
                    if (! (USER_LANGUAGE in {'en': 0, 'en-ca': 0, 'en-gb': 0})) {
                        document.write('<scrip' + 't src="' + STATICS_LOCATION + '/js/app/config/translation/common.' + USER_LANGUAGE + (USE_COMPRESSED_ASSETS ? '.min' : '') + '.js"></scrip' + 't>');
                    }
                }
            })()
        : void(0);

    UI_MODES.getUserLanguage = function(options){
        var
            options = options || {},
            doubleCodeRecognition =
                (typeof options.doubleCodeRecognition !== 'undefined')
                    ? (!! options.doubleCodeRecognition)
                    : true,
            capitalizeLanguageSubsetCode =
                (typeof options.capitalizeLanguageSubsetCode !== 'undefined')
                    ? (!! options.capitalizeLanguageSubsetCode)
                    : true,
            defaultValueReturned =
                (typeof options.defaultValueReturned !== 'undefined')
                    ? options.defaultValueReturned
                    : '',
            returnDefaultValueWithPrefix =
                (typeof options.returnDefaultValueWithPrefix !== 'undefined')
                    ? options.returnDefaultValueWithPrefix
                    : false,
            prefix = options.prefix || '',
            isNotDefaultLanguage = USER_LANGUAGE !== DEFAULT_LANGUAGE && (['en', 'en-ca', 'en-gb'].indexOf(USER_LANGUAGE) === -1),
            userLanguage =
                isNotDefaultLanguage
                    ? USER_LANGUAGE
                    : DEFAULT_LANGUAGE;

        var userLanguageArr = userLanguage.split('-');
        if (capitalizeLanguageSubsetCode) {
            if (userLanguageArr.length !== 1) {
                userLanguageArr[1] = userLanguageArr[1].toUpperCase();
            }
            userLanguage = userLanguageArr.join('-');
        }
        if (! doubleCodeRecognition) {
            userLanguage = userLanguageArr[0];
        }

        if (! isNotDefaultLanguage && typeof options.defaultValueReturned !== 'undefined') {
            return (
                returnDefaultValueWithPrefix && prefix
                    ? (prefix + defaultValueReturned)
                    : defaultValueReturned
            );
        }

        if (prefix) {
            userLanguage = prefix + userLanguage;
        }

        return userLanguage;
    }

})();

var Translations = {
    getMessage: function(msgSelector, defaultValue) {
        var $msg = $('.translatedMessages').find(msgSelector);
        defaultValue = defaultValue || 'Invalid field';
        return $msg.length ? $msg.html() : defaultValue;
    }
};

var PortionRequester = (function(){
    var
        dfdPerId = {},
	    documentReadyFired = null,

        extractFragmentDataFromObject =  function (sourceObject, name) {
            var names = name.split('.');
            var context = sourceObject[names.shift()];
            while ((name = names.shift())) {
                context = context[name];
                if (context === undefined) {
                    break;
                }
            }
            return context;
        },
        getFragmentFromResponse = function(response, fragmentPathCommaSeparatedString){
            var responseFragment = extractFragmentDataFromObject(response, fragmentPathCommaSeparatedString);
            return (
                responseFragment === undefined
                    ?
                        $.Deferred().reject({
                            isUnknownFragment: true,
	                        originalResponse: response
                        })
                    : $.Deferred().resolve(responseFragment)
            ).promise();
        };

    return {
         _parseResponse: function (sessionRequestId, sessionFragmentPathCommaSeparatedString, sessionResponse) {
             var response =
                 dfdPerId[sessionRequestId].response
                     ? dfdPerId[sessionRequestId].response
                     : (dfdPerId[sessionRequestId].response = sessionResponse);

            return getFragmentFromResponse(response, sessionFragmentPathCommaSeparatedString);
        },
        getRequestPortion: function(requestId, options){
	        options = options || {};
            var
	            fragmentPathCommaSeparatedString = options.fragmentPath,
                waitForDocumentReady = options.waitForDocumentReady,
	            requestOptions = options.requestOptions,
                dfd = $.Deferred(),
                dfds = [];

            if (waitForDocumentReady && ! documentReadyFired) {
                var documentReadyDfd = $.Deferred();
                dfds.push(documentReadyDfd);
                $(document).ready(function(){
	                documentReadyFired = true;
	                documentReadyDfd.resolve();
                });
            }
            else {
                dfds.push($.Deferred().resolve().promise());
            }

            if (! (requestId in dfdPerId)) {
	            dfdPerId[requestId] = {};
                dfdPerId[requestId].dfd = $.ajax(requestOptions);
                dfds.push(dfdPerId[requestId].dfd);
            }
            else {
	            if ('response' in dfdPerId[requestId]) {
		            return getFragmentFromResponse(dfdPerId[requestId].response, fragmentPathCommaSeparatedString)
			            .done(dfd.resolve)
			            .fail(dfd.reject);
	            }
	            if (('dfd' in dfdPerId[requestId]) && dfdPerId[requestId].dfd.state() === 'pending') {
                    dfdPerId[requestId]
                        .dfd
                        .then(this._parseResponse.bind(this, requestId, fragmentPathCommaSeparatedString))
                        .done(dfd.resolve)
                        .fail(dfd.reject);

                    return dfd.promise()
	            }
            }

            $.when.apply($, dfds)
                .done(function(documentReadyDfdArguments, requestDfdArguments){
                    this._parseResponse(requestId, fragmentPathCommaSeparatedString, requestDfdArguments[0])
                        .done(dfd.resolve)
                        .fail(dfd.reject)
                }.bind(this))
                .always(function(){
                    delete dfdPerId[requestId].dfd;
                })
                .fail(dfd.reject);

            return dfd.promise();

        },
	    getClientLogo: function() {
            var
                _arguments = Array.prototype.slice.call(arguments),
                options = _arguments[0] || (_arguments[0] = {}),
	            secondaryLogo = options.secondaryLogo || undefined,
                lsClientLogo = Lockr.get('clientLogo'),
                clientLogoData = {
                    sessionId: VITALITYPORTAL_SESSION_UUID,
                    primarySrc: '',
                    secondarySrc: ''
                };

		    options.fragmentPath = 'output.path';
		    options.requestOptions = {
			    dataType: 'json',
			    url: CONTEXT_PATH +
                    // '/js/app/_stabs/_employerLogo_Secondary.json'
                    '/vap/client_logo' + (secondaryLogo ? '?secondary=true' : '')
		    };


            if (! lsClientLogo || lsClientLogo.sessionId !== VITALITYPORTAL_SESSION_UUID) {
                Lockr.set('clientLogo', clientLogoData);
                lsClientLogo = clientLogoData
            }

            return (lsClientLogo && ((lsClientLogo.secondarySrc && !! secondaryLogo) || (lsClientLogo.primarySrc && ! secondaryLogo)))
                        ? $.Deferred().resolve(secondaryLogo ? lsClientLogo.secondarySrc : lsClientLogo.primarySrc).promise()
                        : this.getRequestPortion.apply(this, ['clientLogo' + (secondaryLogo ? 'Secondary' : '')].concat(Array.prototype.slice.call(arguments.length ? arguments : [options])))
                            .done(function(imageSrc) {
                                var clientLogoData = Lockr.get('clientLogo');

                                secondaryLogo ? clientLogoData.secondarySrc = imageSrc : clientLogoData.primarySrc = imageSrc;
                                Lockr.set('clientLogo', clientLogoData);
                            });
        }
    };
})();

var ContextualAsyncModuleRequester = {
    WATCHED_EVENTS: [],
    activateSyncs: function(response){
        var dfd = $.Deferred();
        this.mimicResponse(response)
            .done(function() {
                dfd.resolve();
            })
            .fail(function() {
                dfd.reject();
            });
        return dfd.promise();
    },
    mimicResponse: function(html) {
        var
            dfd = $.Deferred(),
            $html = $('<div />').html(html),
            bootsConstructorDefinitions = $html.find('script[type="text/x-boot-async-module"]');

        if (bootsConstructorDefinitions.length) {
            bootsConstructorDefinitions
                .each(function(i, scriptDom) {
                    var
                        $script = $(scriptDom),
                        eventName = $.trim($script.data('event-name')),
                        scriptHTML = $script.html(),
                        eventDfd;

                    if (eventName && ! this._getWatchedEventByEventName(eventName, true)) {
                        eventDfd =
                            $.Deferred()
                                .done(function() {
                                    this._checkIfAllEventsResolved(eventName)
                                        .done(function() {
                                            dfd.resolve();
                                        });
                                }.bind(this));

                        App.reqres.setHandler(eventName, function(options) {
                            eventDfd.resolve();
                        });

                        this.WATCHED_EVENTS.push({
                            eventName: eventName,
                            eventDfd: eventDfd
                        });
                        this._attachScriptToBodyDom(scriptHTML);
                    }
                    else {
                        eventDfd = this._getWatchedEventByEventName(eventName).eventDfd;
                        if (eventDfd.state()) {
                            eventDfd.done(function(_sessionEventName) {
                                this._checkIfAllEventsResolved(_sessionEventName)
                                    .done(function() {
                                        dfd.resolve();
                                    });
                            }.bind(this, eventName));
                        }
                    }
                }.bind(this));
        }
        else {
            dfd.resolve();
        }

        return dfd.promise();
    },
    _getWatchedEventByEventName: function(eventName, atLeastOne){
        return _[atLeastOne ? 'some' : 'find'](this.WATCHED_EVENTS, function(o) { return o.eventName === eventName; });
    },
    _checkIfAllEventsResolved: function(eventName){
        var
            dfd = $.Deferred(),
            anyPendingEvent = _.some(this.WATCHED_EVENTS, function(o) { return o.eventDfd.state() === 'pending'; });

        if (! anyPendingEvent) {
            dfd.resolve();
        }

        return dfd.promise();
    },
    _attachScriptToBodyDom: function(scriptHTML){
        $('<scrip' + 't>' + scriptHTML + '</scrip' + 't>').appendTo(document.body);
    },
    autoControlVisibility: function (options) {
        var
            dfds = [],
            selector = _.property('selector')(options),
            $scope = _.property('$scope')(options),
            loaderSelector = _.property('loaderSelector')(options),
            contentSelector = _.property('contentSelector')(options),
            $maskedArea =
                (
                    $scope
                        ?
                            selector
                                ? $scope.find(selector)
                                : $scope
                        :
                            $(selector)
                );

        $maskedArea.each(function(i, el) {
            var
                dfd = $.Deferred(),
                $actualMaskedArea = $(el),
                $loader = $actualMaskedArea.find(loaderSelector),
                $content = $actualMaskedArea.find(contentSelector),
                $hideable = $content.length
                    ? $content
                    : $actualMaskedArea;

            dfds.push(dfd);

            $hideable.css({
                visibility: 'hidden'
            });

            $actualMaskedArea.addClass('loading');
            $loader.length && $loader.show();

            this
                .activateSyncs($actualMaskedArea.html())
                .always(function () {
                    dfd.resolve();
                });

            dfd.always(function($actualMaskedArea, $hideable, $loader) {
                $hideable.css({
                        visibility: 'visible'
                    });
                $actualMaskedArea.removeClass('loading');
                $loader.length && $loader.hide();
            }.bind(this, $actualMaskedArea, $hideable, $loader));
        }.bind(this));

        $.when.apply($, dfds).always(function () {});
    }
};

var Surroundings = {
    isTouchDevice: (function () {
        return true === (('ontouchstart' in window) || window.navigator.maxTouchPoints || window.DocumentTouch && document instanceof window.DocumentTouch);
    })(),

    isFullScreen: function(show){
        var
            isFullScreen = document.fullscreenElement || document.mozFullScreenElement || document.webkitFullscreenElement || document.msFullscreenElement,
            afterwordsMethod;

        if (typeof show === 'undefined') {
            return isFullScreen;
        }

        if (!isFullScreen && show) {  // current working methods
            if (document.documentElement.requestFullscreen) {
                afterwordsMethod = _.bind(function(){document.documentElement.requestFullscreen();}, this);
            } else if (document.documentElement.mozRequestFullScreen) {
                afterwordsMethod = _.bind(function(){document.documentElement.mozRequestFullScreen();}, this);
            } else if (document.documentElement.webkitRequestFullscreen) {
                afterwordsMethod = _.bind(function(){
                    document.documentElement.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
                }, this);
            } else if (document.msFullscreenElement) {
                afterwordsMethod = _.bind(function(){document.msRequestFullscreen();}, this);
            }
        }
        else if (isFullScreen && ! show) {
            if (document.cancelFullScreen) {
                afterwordsMethod = _.bind(function(){document.cancelFullScreen();}, this);
            } else if (document.mozCancelFullScreen) {
                afterwordsMethod = _.bind(function(){document.mozCancelFullScreen();}, this);
            } else if (document.webkitCancelFullScreen) {
                afterwordsMethod = _.bind(function(){document.webkitCancelFullScreen();}, this);
            } else if (document.msFullscreenElement) {
                afterwordsMethod = _.bind(function(){document.msExitFullscreen();}, this);
            }
        }
        afterwordsMethod && afterwordsMethod();
        return isFullScreen;

    },

    isClipboardAPISupported: function() {
        var isSafari = !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/);
        return !isSafari;
    },

    isMobileScreenSize: function () {
        return $(window).width() <= 765;
    },

    isChallengesPortal: function() {
        return window.location.href.indexOf('Challenges') !== -1;
    },

    isIOS: function () {
        return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    },

    isAndroid: function () {
        var ua = navigator.userAgent.toLowerCase();

        return ua.indexOf("android") > -1;
    },

    isMobile: function() {
        return this.isIOS() || this.isAndroid();
    },

    isFF: function() {
        return navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
    },

    isChrome: function () {
        return /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
    }

};

var CREDIT_CARD_TYPE = {
    detectCreditCardType: function creditCardType(cc) {
        var amex = new RegExp('^3[47][0-9]{13}$'),
            visa = new RegExp('^4[0-9]{12}(?:[0-9]{3})?$');

        var mastercard = new RegExp('^5[1-5][0-9]{14}$'),
            mastercard2 = new RegExp('^2[2-7][0-9]{14}$');

        var disco1 = new RegExp('^6011[0-9]{12}[0-9]*$'),
            disco2 = new RegExp('^62[24568][0-9]{13}[0-9]*$'),
            disco3 = new RegExp('^6[45][0-9]{14}[0-9]*$');


        if (visa.test(cc)) {
            return 'visa';
        }
        if (amex.test(cc)) {
            return 'americanexpress';
        }
        if (mastercard.test(cc) || mastercard2.test(cc)) {
            return 'mastercard';
        }
        if (disco1.test(cc) || disco2.test(cc) || disco3.test(cc)) {
            return 'discover';
        }
        return undefined;
    }
}

var IMAGE_UTILS = ({
    MAX_PIXEL_RATIO: 3,
    DEVICE_PIXEL_RATIO: Math.round(window.devicePixelRatio) || 1,
    CURRENT_PIXEL_RATIO: null,
    initialize: function () {
        this.CURRENT_PIXEL_RATIO =
            this.DEVICE_PIXEL_RATIO > this.MAX_PIXEL_RATIO
                ? this.MAX_PIXEL_RATIO
                : this.DEVICE_PIXEL_RATIO;
        return this;
    },
    retinizeBgImage: function ($el) {
        var
            src = $el.data('bg-src') || '';
        if ($.trim(src) === '') {
            return false;
        }

        var
            extPosition = src.lastIndexOf('.'),
            imageName = src.substring(0, extPosition),
            imageExtension = src.substring(extPosition),
            genericSrcMaskWithRatio = imageName + '@{{RATIO}}x' + imageExtension;

        $el
            .data('image-optimize-retina-handled-ratio', this.CURRENT_PIXEL_RATIO)
            .data('image-optimize-retina-bg-src-mask', genericSrcMaskWithRatio);

        this.loadRetinaImage($el);
    },
    updateCSSBgProperty: function ($el, src, bool) {
        if (bool) {
            $el.css('background-image', 'url(' + src.replace(/ /g, '%20') + ')');
        }
        else {
            var currentHandledRatio = $el.data('image-optimize-retina-handled-ratio');
            if (currentHandledRatio > 2) {
                currentHandledRatio--;
                $el.data('image-optimize-retina-handled-ratio', currentHandledRatio);
                this.loadRetinaImage($el);
            }
            else {
                $el.css('background-image', 'url(' + $el.data('bg-src').replace(/ /g, '%20') + ')');
            }
        }
    },

    loadRetinaImage: function ($el) {
        if (this.CURRENT_PIXEL_RATIO >= 2) {
            var $img = $('<img/>')
                .appendTo(document.body)
                .css({
                    width: '1px',
                    height: '1px',
                    position: 'absolute',
                    left: '-999px',
                    top: '-999px'
                });

            $img
                .attr('src', $el.data('image-optimize-retina-bg-src-mask').replace(/{{RATIO}}/g, $el.data('image-optimize-retina-handled-ratio')).replace(/ /g, '%20'))

                .on('load', function ($sessionImg, $sessionEl) {
                    var src = $sessionImg.attr('src');
                    $sessionImg.remove();
                    this.updateCSSBgProperty($sessionEl, src, true);
                }.bind(this, $img, $el))

                .on('error', function ($sessionImg, $sessionEl) {
                    $sessionImg.remove();
                    this.updateCSSBgProperty($sessionEl, null, false);
                }.bind(this, $img, $el));
        }
        else {
            this.updateCSSBgProperty($el, $el.data('bg-src'), true);
        }
    },
	setHeaderEmployerLogo: function(){
		var $clientLogo = $('.fromVRSEmployerLogo');
		if ($clientLogo.length > 0){
			if ($.trim($clientLogo.attr('src')) === '') {
				$clientLogo
                    .attr('data-client-logo-automatically-blanked', true)
				    .attr('src', BASE_64_SINGLE_PIXEL_CODE);
			}
			PortionRequester
				.getClientLogo({
					fragmentPath: 'output.path',
					waitForDocumentReady: true
				})
				.done(function(response) {
					$clientLogo.attr('src', response);
				});
		}
	},
  apadtLangDependableImage: function ($el) {

    // <img class="langDependableSrc" data-attr-lang-dependable-skip-subfamilies="all" data-attr-lang-dependable-srcset-fr="//cdn.powerofvitality.com/TVG/Rewards/appleWatch/get_aw_manulife_fr.png,  //cdn.powerofvitality.com/TVG/Rewards/appleWatch/get_aw_manulife_fr_retina.png 2x" data-attr-lang-dependable-srcset-default="//cdn.powerofvitality.com/TVG/Rewards/appleWatch/get_aw_manulife.png, //cdn.powerofvitality.com/TVG/Rewards/appleWatch/get_aw_manulife_retina.png 2x" src="data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" alt="">
    var
      currentUserLanguage,
      skipLangSubFamilies = $.trim($el.data('attr-lang-dependable-skip-subfamilies') || '').toLowerCase();
    if (skipLangSubFamilies) {
      if (skipLangSubFamilies === 'all') {
        currentUserLanguage = USER_LANGUAGE.split('-')[0];
      }
      else {
        skipLangSubFamilies.split(',').forEach(function (langCode, indx, skipLangSubFamilies) {
          var cleanedLangCode = $.trim(langCode.split('-')[0]);
          if (USER_LANGUAGE.split('-')[0] === cleanedLangCode) {
            currentUserLanguage = cleanedLangCode;
          }
        });
      }
    }

    if (!currentUserLanguage) {
      currentUserLanguage = USER_LANGUAGE;
    }

    var
      srcsetAttrValue = $.trim($el.data('attr-lang-dependable-srcset-' + currentUserLanguage)),
      srcAttrValue = $.trim($el.data('attr-lang-dependable-src-' + currentUserLanguage)),
      isBackgroundValue = !!$el.data('attr-lang-dependable-bg') || false;

    // srcset
    if (srcsetAttrValue.length) {
      if (isBackgroundValue) {
        var srcSetToArray = srcsetAttrValue.split(','),
          srcSet1x = $.trim(srcSetToArray[0]),
          srcSet2x = $.trim(srcSetToArray[1]).split(' ')[0];

        $el.attr('style', 'background-image: url(' + srcSet1x + ');' +
          'background-image:-webkit-image-set(url(' + srcSet1x + ') 1x, url(' + srcSet2x + ') 2x);');
      } else {
        $el.prop('srcset', srcsetAttrValue);
      }
    }
    else {
      var srcsetAttrDefaultValue = $.trim($el.data('attr-lang-dependable-srcset-default'));

      if (isBackgroundValue) {
        var srcSetDefaultToArray = srcsetAttrDefaultValue.split(','),
          srcSetDefault1x = $.trim(srcSetDefaultToArray[0]),
          srcSeDefaultt2x = $.trim(srcSetDefaultToArray[1]).split(' ')[0];


        $el.attr('style', 'background-image: url(' + srcSetDefault1x + ');' +
          'background-image:-webkit-image-set(url(' + srcSetDefault1x + ') 1x, url(' + srcSeDefaultt2x + ') 2x);');
      } else {
        $el.prop('srcset', srcsetAttrDefaultValue);
      }
    }

    // src
    if (srcAttrValue.length) {
      if (isBackgroundValue && !srcsetAttrValue.length) {
        $el.css('background-image', 'url(' + srcAttrValue + ')');
      } else {
        $el.prop('src', srcAttrValue);
      }
    }
    else {
      var srcAttrDefaultValue = $.trim($el.data('attr-lang-dependable-src-default'));
      if (isBackgroundValue && !srcAttrDefaultValue.length) {
        $el.css('background-image', 'url(' + srcAttrDefaultValue + ')');
      } else {
        $el.prop('src', srcAttrDefaultValue);
      }
    }
  },
  trackImagesLoad: function ($scope, callback) {
    var
      $imgs = $scope.find('img'),
      unLoadedImagesBulk = new Array($imgs.length);

    if (!$imgs.length) {
      callback();
    }

    $imgs
      .each(function (i, el) {
        var $el = $(el);
        $el
          .on('load error', function () {
            unLoadedImagesBulk.splice(0, 1);
            if (!unLoadedImagesBulk.length) {
              callback();
            }
          }.bind(this));

        if (el.complete) {
          $el.load();
        }
      });
  }
}).initialize();

var IFRAME_UTILS = {
    resizeWatcher: function ($iframe) {
        window.addEventListener("message", function(postMessage) {
            var message = typeof postMessage.data === 'string' && JSON.parse(postMessage.data);
            if (message && message.eventType === 'iframe-utils:resize') {
                message.eventData && message.eventData.height && $iframe.height(message.eventData.height);
            }
        }, false);
    },
    inIframe: function () {
        try {
            return window.self !== window.top;
        } catch (e) {
            return true;
        }
    }
};

var GA_Broker = (function(){
    var isBeaconTransportSupported = window.navigator.sendBeacon;

    /**
     * Determines if a link click event will cause the current page to upload.
     * Note: most link clicks *will* cause the current page to unload because they
     * initiate a page navigation. The most common reason a link click won't cause
     * the page to unload is if the clicked was to open the link in a new tab.
     * @param {Event} event The DOM event.
     * @param {Element} link The link element clicked on.
     * @return {boolean} True if the current page will be unloaded.
     */
    var isClickWillUnloadCurrentPage = function (event) {
        var isLinkWithBlank = event.currentTarget
            && event.currentTarget.target
            && event.currentTarget.target === '_blank';
        return !(
            // The event type can be customized; we only care about clicks here.
        event.type != 'click' ||
            // Links with target="_blank" set will open in a new window/tab.
        isLinkWithBlank ||
            // On mac, command clicking will open a link in a new tab. Control
            // clicking does this on windows.
        event.metaKey || event.ctrlKey ||
            // Shift clicking in Chrome/Firefox opens the link in a new window
            // In Safari it adds the URL to a favorites list.
        event.shiftKey ||
            // On Mac, clicking with the option key is used to download a resouce.
        event.altKey ||
            // Middle mouse button clicks (which == 2) are used to open a link
            // in a new tab, and right clicks (which == 3) on Firefox trigger
            // a click event.
        event.which > 1);
    };

    /**
     * Accepts a function and returns a wrapped version of the function that is
     * expected to be called elsewhere in the system. If it's not called
     * elsewhere after the timeout period, it's called regardless. The wrapper
     * function also prevents the callback from being called more than once.
     * @param {Function} callback The function to call.
     * @param {number} wait How many milliseconds to wait before invoking
     *     the callback.
     * @returns {Function} The wrapped version of the passed function.
     */
    var withTimeout = function(callback, wait) {
        var called = false;
        var fn = function() {
            if (!called) {
                called = true;
                callback();
            }
        };
        setTimeout(fn, wait || 2000);
        return fn;
    };

    var Broker = {
        onTriggerClick: function(e, options, force){
            var
                options = options || {},
                $el = $(e.currentTarget),
                isFormSubmit = $el.data('ga-broker-form-submit') !== undefined ? true : false,
                brokerInlineHitType = $el.data('ga-broker-hit-type'),
                brokerInlineEventCategory = $el.data('ga-broker-event-category'), // Text
                brokerInlineEventAction = $el.data('ga-broker-event-action'), // Text
                brokerInlineEventLabel = $el.data('ga-broker-event-label'), // Text
                brokerInlineEventValue = $el.data('ga-broker-event-value'), // Integer
                nonInteract = _.property('nonInteract')(options);

                if (nonInteract !== undefined) {
                    delete options['nonInteract'];

                    if (nonInteract) {
                        return false;
                    }
                }


            var
                gaOptions = _.extend(
                    {
                        hitType: brokerInlineHitType ? brokerInlineHitType : 'event'
                    },
                    (brokerInlineEventCategory ? {
                        eventCategory: brokerInlineEventCategory
                    } : {}),
                    (brokerInlineEventAction ? {
                        eventAction: brokerInlineEventAction
                    } : {}),
                    (brokerInlineEventLabel ? {
                        eventLabel: brokerInlineEventLabel
                    } : {}),
                    (brokerInlineEventValue ? {
                        eventValue: brokerInlineEventValue
                    } : {}),
                    options
                );

            if ((! isBeaconTransportSupported && isClickWillUnloadCurrentPage(e)) || force) {
                var href = $(e.currentTarget).attr('href');
                // Checks to make sure another event handler hasn't already prevented
                // the default action. If it has the custom redirect isn't needed.
                var proceedOperationEventData = {
                    proceed: true,
                    awaitingGATransportFallbackRelocation: false
                };

                e.originalEvent && _.extend(e.originalEvent, proceedOperationEventData);

                if (! e.defaultPrevented && proceedOperationEventData.proceed) {
                    // Stops the click and waits until the hit is complete (with timeout)
                    // for browsers that don't support beacon.
                    e.preventDefault && e.preventDefault();
                    e.originalEvent && _.extend(e.originalEvent, {
                        awaitingGATransportFallbackRelocation: true
                    });

                    gaOptions.hitCallback = withTimeout(function (session$E, session$El, sessionHref, sessionIsFormSubmit) {
                        if (
                            ! session$E.originalEvent // programmatic call
                            ||
                            (session$E.originalEvent && session$E.originalEvent.awaitingGATransportFallbackRelocation) // natural event-driven process with no additional GA relocation preventions
                        )
                        {
                            if (sessionIsFormSubmit) {
                                session$El.closest('form').submit();
                            }
                            else if (href) {
                                location.href = href;
                            }
                        }
                    }.bind(this, e, $el, href, isFormSubmit), GA_BROCKER_TIMEOUT);
                    ga('send', gaOptions);
                }
            }
            else {
                ga('send', gaOptions);
            }
        }
    };

    return Broker;
})();

(function(){

    function prolongSession() {
        $.ajax({
            url: CONTEXT_PATH + "/test/prolong_session",
            timeout: 5000,
            cache: false,
            success: function(data) {},
            error: function() {}
        });
    }

    // ajaxSuccess, ajaxError
    var securityTokenRetrieve = new (function(){
        var _securityToken;
        return function () {
            if (_securityToken) {
                return _securityToken;
            }
            var $security_token = $('#meta_security_token');
            _securityToken = $security_token.length && $security_token.attr('content');
            return (!! _securityToken) && _securityToken;
        };
    });

    $(document).ajaxSend(function(event, jqXHR, ajaxOptions) {
        var securityToken =  securityTokenRetrieve();
        if (securityToken) {
            jqXHR.setRequestHeader('X-CSRF-Token', securityToken);
        }
    });

    $.ajaxSetup({
        success: function(result, status, xhr) {
            handleResponse(xhr);
        }
    });
})(this);

$(function () {

    $(document).on('click', '[data-ga-broker-on]', function(e, evData){
        GA_Broker.onTriggerClick(e, evData);
    });

    //apply :hover class for menu only for non-touch devices
    if (!Surroundings.isTouchDevice) {
        $('#menu .dropdown').addClass("no-touch");
    }

    $.fn.hideIconForTouchDevice = function () {
        //hide printer icon for mobile devices
        if (Surroundings.isMobile()) {
            this.hide();
        }
    };

    $.fn.centerPopup = function () {
        this.css("position", "fixed");
        this.css("left", Math.max(0, (($(window).width() - $(this).outerWidth()) / 2)));
        return this;
    };

    try {
        $(window).trigger('slidebars:onBeforeInitialized');
        $.slidebars({
            disableOver: 768,
            scrollLock: true
        });
    } catch ($e) {
        console.debug($e);
    }

    var dateFormat = DATE_FORMAT;

    if($.fn.datepicker) {
        if (! $.fn.datepicker.defaults) {
            $.fn.datepicker.defaults = {}
        }
      $.fn.datepicker.defaults.format = dateFormat;
    }

    var oldDatepicker = $.fn.datepicker;
    $.fn.datepicker = function () {
        var
            $this = $(this),
            $input,
            placeholder = dateFormat;

        if(isLanguageFrCa()) {
            placeholder = dateFormat.replace('dd', 'jj').replace('yyyy', 'aaaa');
        }

        // set placeholder
        $input = ($this.length && $this.prop("tagName") === 'INPUT') ? $this : $this.find('input');
        $input.length && !$input.attr('placeholder') && $input.attr('placeholder', placeholder);
        $this.closest('div').refineFormPlaceholders();

        return oldDatepicker.apply(this, arguments);
    };

    if(typeof addCustomXMLRequestCallback === 'function') {
        addCustomXMLRequestCallback(handleResponse);
    }

    $(document).on(
        'click',
        '#accountMenu', // comma separated
        function (e) {
            e.preventDefault();
            e.stopPropagation();
        }
    );

    // Custom drop down for points statment filters date picker
    var flagVisibility = false; // global flag to check if datepicker opens.
    $('.date[data-basic-control]').each(function () {
        $(this).datepicker({
            autoclose: true
        }).on('show', function () {
            flagVisibility = true;
        })
            .on('hide', function () {
                flagVisibility = false;
            })
    });

    var toggleCustomDropDown = function(action) {
        var _parent = $(this).parent('.customDropDown');
        var _menu = _parent.find('.dropdown-menu');

        if(!action) {
            action = _parent.hasClass('open') ? 'close' : 'open';
        }

        var panelGroupDataSelector = 'data-panel-group',
            collapsedItemsSelector = 'a[data-toggle="collapse"].collapsed',
            notCollapsedItemsSelector = 'a[data-toggle="collapse"]:not(.collapsed)',
            $panelGroupDataActualItems = _menu
                .find('[' + panelGroupDataSelector + ']')
                .filter('[' + panelGroupDataSelector + '-is-actual]'),
            $collapseTogglers;

        if (action === 'close') {
            _parent.removeClass('open');
            _menu.hide();

            //hide filters
            $collapseTogglers = ($panelGroupDataActualItems.length > 0)
                ? $panelGroupDataActualItems.find(notCollapsedItemsSelector)
                : _menu.find(notCollapsedItemsSelector);

	        if ($collapseTogglers.length > 1){ // Close all if there more then one panel in the list
                $collapseTogglers.each(function(i, el){
                    var $el = $(el);
                    $el.addClass('collapsed');
                    _menu.find($el.attr('href')).removeClass('in');
                });
            }
        }
        else {
            _parent.addClass('open');
            _menu.show();

            // show filters
            $collapseTogglers = ($panelGroupDataActualItems.length === 1)
                ? $panelGroupDataActualItems.find(collapsedItemsSelector)
                : _menu.find(collapsedItemsSelector);

            if ($collapseTogglers.length === 1) { // Open if only one default panel in the list
                $collapseTogglers.trigger('click');
            }
        }
    };
    //Custom drop down
    $(document).on('click', '.customDropDown > .btn', function() {
            _.bind(toggleCustomDropDown, this)();
        }) .on('open.custom', function() {
            _.bind(toggleCustomDropDown, this, 'open')();
        }).on('close.custom', function() {
            _.bind(toggleCustomDropDown, this, 'close')();
        });

    $(document).on('mouseup', function (e) {
        // here define containers which need to be hidden on click outside event
        var container = $('.customDropDown');
        var item = $('.customDropDown > .dropdown-menu');
        if (!flagVisibility && container
            && !container.is(e.target) // if the target of the click isn't the container...
            && container.has(e.target).length === 0) // ... nor a descendant of the container
        {
            if(!container.is("[no-common-document-hide]")){
                item.hide();
                container.removeClass('open');
            }

            //hide filters
            item.find('a[data-toggle="collapse"]:not(.collapsed)').trigger('click');
        }

        var accountContainer = $('.account-dropdown');
        if (!accountContainer.is(e.target) // if the target of the click isn't the container...
            && accountContainer.has(e.target).length === 0) // ... nor a descendant of the container
        {
            accountContainer.removeClass('open');
        }
    });

    $('#accountMenu').hover(function () {
        var $el = $(this).parent();
        !Surroundings.isTouchDevice && $el.addClass('open').find('ul[role=menu]').attr('aria-hidden', false);
    }).on('open.custom', function() {
        var $el = $(this).parent();
        $el.addClass('open').find('ul[role=menu]').attr('aria-hidden', false);
    });
    $('.account-dropdown').mouseleave(function () {
        $(this).removeClass('open').find('ul[role=menu]').attr('aria-hidden', true);
    });

    /* Phones view. Add background color when click on sub navigation item in main navigation TVGUSA-19442*/
    $('#menuSlidebar .panel .subNav a').on('click', function () {
        $(this).parents('.panel').find('.subNav').find('a').removeClass('active');
        $(this).addClass('active');
        $("#res-menu").click();
    });

    if (Surroundings.isTouchDevice) {
        $('#menu .dropdown').click(function (e) {
            if (e.target.tagName === 'A' && e.target.parentNode !== e.currentTarget || $(e.target).closest(".dropdown-menu").length !== 0)
                return;

            e.preventDefault();
            var $el = $(e.currentTarget);

            $el.parent().find('li.dropdown.hover').removeClass('hover');
            $el.toggleClass('hover');
        });
        $(document).click(function (e) {
            var container = $('#menu');
            var item = $('#menu .dropdown.hover');
            if (container
                && !container.is(e.target) // if the target of the click isn't the container...
                && container.has(e.target).length === 0) // ... nor a descendant of the container
            {
                item.removeClass('hover');
            }
        });
        $("#menu .dropdown .dropdown-menu a").click(function (){
            $('#menu .dropdown').removeClass("hover");
        });
    }

    //TVGUSA-22525
    if ($('.modal').filter(':visible').length) {
        disableWindowScroll();
    }
    $('body').on('show.bs.modal', function(){
        disableWindowScroll(true);
    });
    $('body').on('shown.bs.modal', '.modal', function () {
        disableWindowScroll();
        $('#sb-site').attr('aria-hidden', 'true');

        // autofocus some modal element
        var autoFocusAttr = $(this).attr('data-aoda-autofocus');
        if(autoFocusAttr == 'true') {
            var autoFocusSelectorAttr = $(this).attr('data-aoda-autofocus-nested-selector'),
                $autoFocusElem = $(autoFocusSelectorAttr).length ? $(autoFocusSelectorAttr, this) : $(this).find('.basicPopupClose');

            if($autoFocusElem.length) {
                setTimeout(function(){$autoFocusElem.eq(0).focus()}, 300);
            }
        }
        // change attributes
        $(this)
            .attr('aria-hidden', 'false')
            //.attr('tabindex', '0'); // TVGUSA-55462
    });
    $('body').on('hidden.bs.modal', '.modal', function () {
        restoreWindowScroll();
        $('#sb-site').attr('aria-hidden', 'false');

        // change attributes
        $(this)
            .attr('aria-hidden', 'true')
            //.attr('tabindex', '-1');

        $('.modal:visible').length && $('body').addClass('modal-open'); // if two modals or more modals were open
    });

    $('body').on('hide.bs.modal', '.basicPopup', function () {
        if (! $('.modal:visible').length) {
            restoreTabindexToElements($(tabActiveElementsList).not($(this).find(tabActiveElementsList)));
        }
    });
    // Fix bug with swip on basicPopup
    $('body').on('shown.bs.modal', '.basicPopup', function () {
        var self = this;
        if($(this).attr('data-iScroll-disabled') !== 'true') {

            window.setTimeout(function() {
                var basicPopupScroll = new IScroll(self, {
                    scrollbars: true,
                    mouseWheel: true,
                    interactiveScrollbars: true,
                    fadeScrollbars: false,
                    bounce: false,
                    click: false,
                    preventDefault: false, // TVGUSA-41106. Select text and value from fields
                    preventDefaultException: {tagName: /.*/},
                    keyBindings: {
                        pageUp: 33,
                        pageDown: 34,
                        end: 35,
                        home: 36,
                        left: 37,
                        up: 38,
                        right: 39,
                        down: 40
                    }
                });

                var normalizeScrollPosition = function() {
                    var
                        oldScrollY = $(self).scrollTop(),
                        oldScrollX = $(self).scrollLeft();

                    if(oldScrollY || oldScrollX) {
                        $(self).scrollTop(0);
                        $(self).scrollLeft(0);
                        basicPopupScroll.scrollBy(-oldScrollX, -oldScrollY);
                    }
                };

                $.data(self, "iScroll", basicPopupScroll);
                $(self).trigger('iScrollAttached');
                normalizeScrollPosition();

                $(self).on('keyup', function(e) {
                    var keyCode = e.keyCode || e.which;
                    if (keyCode == 9) {
                        normalizeScrollPosition();
                    }
                });

                if (Surroundings.isIOS()) {
                    var refreshIScroll = function() {
                        basicPopupScroll.refresh();
                        $(window).off('touchend', refreshIScroll)
                    };

                    $(window).load(function(){
                        basicPopupScroll.refresh();
                    });

                    $(window).on('touchend', refreshIScroll);
                }
            }, 300);

        }

        if($(this).data('bs.modal')) {
            $(this).data('bs.modal').options.backdrop = 'static';
        }

        // AODA
        //$(self).find('a.btn, a.basicButton, button').eq(0).focus(); TODO: cannot scroll to bottom buttons

        setTabIndexToElements($(tabActiveElementsList).not($(self)).not($(self).find(tabActiveElementsList)), '-7');
        $('body').on('contentUpdated.modalShown', function() {
            setTabIndexToElements($(tabActiveElementsList).not($(self)).not($(self).find(tabActiveElementsList)), '-7');
        });

        $(this).find('a, button').off('keydown.aoda');
        $(this).find('a, button').on('keydown.aoda', function(e) {
            var triggerEvent = $(this).attr('data-onpressenter-trigger') || 'click';
            if(e.keyCode === 13 || (e.keyCode === 32 && !$(e.target).is('input:checkbox'))) { // press enter
                $(this).trigger(triggerEvent);
            }
        });

        restoreTabindexToElements($(self).find(tabActiveElementsList)); //

        // TVGUSA-48014
        $(document)
            .off('focusin.bs.modal') // guard against infinite focus loop
            /*.on('focusin.bs.modal', $.proxy(function (e) {
                var $element = $(this);
                if ($element[0] !== e.target && !$element.has(e.target).length) {
                    var $innerActiveElement = $element.find(window.tabActiveElementsList).eq(0);
                    if($innerActiveElement.length && !($innerActiveElement.attr('tabindex') && $innerActiveElement.attr('tabindex') < 0)) {
                        $innerActiveElement.focus();
                    } else {
                        $element.trigger('focus');
                    }
                }
            }, this));*/ // TVGUSA-52618
    });

    $('body').on('hidden.bs.modal', '.basicPopup', function () {
        if (! $('.modal:visible').length) {
            var $el = $(this), id = 'iScroll', _instIScroll = $el.data(id);
            if (_instIScroll) {
                $el.data(id).destroy();
                $el.removeData(id);
                _instIScroll = null;
            }
            $('body').off('contentUpdated.modalShown');
            restoreTabindexToElements($('[data-tabindex-init]'));
        }
        else {
            restoreTabindexToElements($('.modal:visible').find(tabActiveElementsList));
        }
    });

    $('#globalMenu li.dropdown').on('open.custom', function() {
        $(this).closest('li').addClass('hover');
    });
    $('#globalMenu li.dropdown').on('close.custom', function() {
        $(this).closest('li').removeClass('hover');
    });

    $('#editImageButton').on('focus', function() {
        $(this).addClass('show');
    }).on('blur', function () {
        $(this).removeClass('show');
    });

    // AODA

    // [data-activate-by-focus] - activate element by focus
    // [data-onpressenter-trigger] - what event need to trigger by press enter
    // [data-onblur-trigger] - what event need to trigger by focus out
    // [data-related-block-tabindex-inc] - increase tabindex of related block (help if related block located before main contol)
    var $currentFocusedElement = null;
    window.tabActiveElementsList = 'a,button,input,select,textarea,[tabindex^="-7"],[tabindex^="0"],[tabindex^="1"],[tabindex^="2"],[tabindex^="3"],[tabindex^="4"],[tabindex^="5"],[tabindex^="6"],[tabindex^="7"],[tabindex^="8"],[tabindex^="9"]';
    $('body').on('focus', tabActiveElementsList, function() { // check on element, not only in aodaOperable containers
        $currentFocusedElement = $(this);
    });
    $('.aodaOperable').find('input:file').on('focus', function() {
        var $label = $(this).closest('label').length ? $(this).closest('label') : null;

        if(!$label) {
            $label = $(this).prevAll('label');
        }
        if(!$label) {
            $label = $(this).nextAll('label');
        }

        $label && $label.length && $label.addClass('attachFileFocus');
    }).on('blur', function() {
        var $label = $(this).closest('label').length ? $(this).closest('label') : null;

        if(!$label) {
            $label = $(this).prevAll('label');
        }
        if(!$label) {
            $label = $(this).nextAll('label');
        }

        $label && $label.length && $label.removeClass('attachFileFocus');
    });

    var lastClickedElement;
    $('body').on('mousedown', tabActiveElementsList, function(e) {
        lastClickedElement = e.target;
    });

    window.checkHowElementFocused = function ($element) {
        // TVGUSA-49530 (check Surroundings.isTouchDevice)
        if(Surroundings.isTouchDevice || ($element[0] === $(lastClickedElement)[0] || $element[0] === $(lastClickedElement).closest(tabActiveElementsList)[0])) {
            return 'click';
        } else {
            return 'tab';
        }
    };

    var onAodaOperableElementFocus = function(e, withActivate) {
        if(!$(this).data('prevFocusedElement')) {
            $.data($(this)[0], "prevFocusedElement", $currentFocusedElement);
        }

        $currentFocusedElement = $(this);

        var activateElement = _.bind(function () {
            var onEnterKeyPressTrigger = $(this).attr('data-onpressenter-trigger') || 'click';
            var relatedBlock = $(this).attr('data-related-block') ? $(this).attr('data-related-block') : null;

            if (! withActivate && (! $(this).is('[data-onpressenter-skip-trigger]') )){
                //setTimeout(function () { // DEBUG: to and capture programmatically occurred 'click'
                    $(this).trigger(onEnterKeyPressTrigger);
                //}.bind(this), 3000);
            }

            $(this).addClass('aoda-activated');

            var setRelatedBlockTabindex = _.bind(function() {
                var parentTabindex = $(this).attr('tabindex'),
                    relatedBlockElementsTabindex,
                    $relatedBlock = $(relatedBlock);

                if(typeof parentTabindex === 'undefined') {
                    relatedBlockElementsTabindex = 0;
                } else {
                    if($(this).attr('data-related-block-tabindex-inc')) {
                        relatedBlockElementsTabindex = parseInt(parentTabindex) + 1;
                    } else {
                        relatedBlockElementsTabindex = parentTabindex;
                    }
                }

                if($relatedBlock && $relatedBlock.length) {
                    $relatedBlock.find(tabActiveElementsList).each(function () {
                        if (typeof $(this).attr('tabindex') !== 'undefined' || ['a', 'button', 'input', 'select', 'textarea'].indexOf($(this)[0].tagName.toLowerCase()) > -1) {
                            if (typeof $(this).attr('data-tabindex-init') === 'undefined') {
                                $(this).attr('data-tabindex-init', $(this).attr('tabindex') ? $(this).attr('tabindex') : 0);
                            }
                            if (!$(this).attr('tabindex') || $(this).attr('tabindex') > -1) {
                                $(this).attr('tabindex', relatedBlockElementsTabindex);
                            }
                        }
                    });

                    // check included bootstrap modals
                    var relatedBlockWithModalIds = $(this).attr('data-related-block');
                    $relatedBlock.find('[data-target^="#"]').each(function () {
                        relatedBlockWithModalIds += relatedBlockWithModalIds ? ',' + $(this).attr('data-target') : $(this).attr('data-target');
                    });
                    $(this).attr('data-related-block', relatedBlockWithModalIds);
                }

            }, this);

            setRelatedBlockTabindex();

            $(relatedBlock) && $(relatedBlock).length && $(relatedBlock).off('content.updated');
            $currentFocusedElement && $currentFocusedElement.off('content.updated');
            $(relatedBlock) && $(relatedBlock).length && $(relatedBlock).on('content.updated', setRelatedBlockTabindex);
            $currentFocusedElement.on('content.updated', setRelatedBlockTabindex);
            //}
        }, this);

        var flagActivateByFocus = $(this).attr('data-activate-by-focus');
        if((flagActivateByFocus && checkHowElementFocused($(this)) === 'tab') || withActivate) {
            activateElement();
        }

        $(this).off('keydown.aoda');
        $(this).on('keydown.aoda', function(e) {
            if (e.keyCode === 13 || e.keyCode === 32) { // press enter or space
                if (e.keyCode === 13) {
                  // Enter key causes a manual click trigger
                  activateElement();
                }
                var
	                $currentTarget = $(e.currentTarget),
                    skipSpaceCode = (e.keyCode === 32),
                    nonInputElement = ! $currentTarget.is('a, select, input, textarea'),
                    aodaEventPreventableValue = $currentTarget.data('aoda-event-preventable'),
	                aodaEventPreventableBool = (aodaEventPreventableValue === 'undefined') || (aodaEventPreventableValue !== false);

                if (skipSpaceCode && nonInputElement && aodaEventPreventableBool){
                    e.preventDefault();
                }
            }
        });
    };

    var onAodaOperableElementBlur = function() {
        var self = $(this);
        window.setTimeout(function () {

            $('.aoda-activated').not($currentFocusedElement).each(function () {
                var $relatedBlock = $(this).attr('data-related-block') ? $($(this).attr('data-related-block')) : null;
                var onBlurTrigger = $(this).attr('data-onblur-trigger');

                var iscurrentFocusedElementInRelatedModal = $currentFocusedElement.closest('.modal').length && $relatedBlock.find('*[data-target="#'+$currentFocusedElement.closest('.modal').attr('id')+'"]');
                if (!$relatedBlock || !$relatedBlock.length ||
                    ($currentFocusedElement && checkIssetElement($currentFocusedElement) && !$relatedBlock.has($currentFocusedElement).length) && !iscurrentFocusedElementInRelatedModal
                ) {
                    onBlurTrigger && $(this).trigger(onBlurTrigger);
                    $(this).off('keydown.aoda');
                    $(this).removeClass('aoda-activated');
                    if ($relatedBlock && $relatedBlock.length) {
                        $relatedBlock.find(tabActiveElementsList).each(function () {
                            $(this).attr('tabindex', $(this).attr('data-tabindex-init'));
                        });
                    }
                }
            });

        }, 50);

        if (checkIsElementHidden($(self))) {
            findOtherElementForFocus($(self));
        }
    };

    $('body').on('focus', '.dynamicAodaOperable', function(e) {
        var el = e.currentTarget;
        if($(el).is(tabActiveElementsList)) {
            _.bind(onAodaOperableElementFocus, el)(e);
        }
    }).on('blur', '.dynamicAodaOperable', function(e) {
        var el = e.currentTarget;
        if($(el).is(tabActiveElementsList)) {
            _.bind(onAodaOperableElementBlur, el)(e);
        }
    });

    $('.aodaOperable')
        .on('focus', tabActiveElementsList, function(e) {
            window.checkHowElementFocused($(this)) === 'tab' && _.bind(onAodaOperableElementFocus, this)(e);
        })
        .on('aoda.activate', tabActiveElementsList, function(e) {
            _.bind(onAodaOperableElementFocus, this)(e, true);
        })
        .on('blur', tabActiveElementsList, function(e) {
            window.checkHowElementFocused($(this)) === 'tab' && _.bind(onAodaOperableElementBlur, this)(e);
        });

    function checkIssetElement($el) {
        return !$el.length || !$('body').has($el).length ? false : true;
    }

    function checkIsElementHidden ($el) {
        var isHidden = !$el.hasClass('dontCheckHidden') && !$el.attr('data-aoda-dont-check-hidden') && ($el.is(':hidden') || $el.css("visibility") === "hidden" || $el.css("opacity") === 0);
        if(isHidden && $el.is('input:radio, input:checkbox, input:file')) {
            isHidden = checkIsElementHidden($el.parents().eq(0));
        }

        return isHidden;
    };

    function findOtherElementForFocus($checkEl) {
        // find elements in related block
        if($checkEl.attr('data-related-block')) {
            var $relatedBlock = $($checkEl.attr('data-related-block'));
            if($relatedBlock && $relatedBlock.length && !checkIsElementHidden($relatedBlock)) {
                var $relatedBlockElements = $relatedBlock.find(tabActiveElementsList).filter(':visible');
                $relatedBlockElements.eq(0).length && $relatedBlockElements.eq(0).focus();

                return true;
            }
        }
        // find prevent element
        var i = 0;
        while($checkEl && $checkEl.data('prevFocusedElement')) {
            i++;
            if(
                $checkEl.data('prevFocusedElement').length &&
                !checkIsElementHidden($checkEl.data('prevFocusedElement')) &&
                (!$checkEl.data('prevFocusedElement').hasClass('dontCheckHidden') || !checkIsElementHidden($checkEl.data('prevFocusedElement').parents().eq(0)))) {
                $checkEl.data('prevFocusedElement').focus();
                isPrevElementFocused = true;

                return true;
            }

            if(i > 20) {
                break;
            }

            $checkEl = $checkEl.data('prevFocusedElement');
        }

        // find parent
        var $parent,
            $nextElement;

        $('.aoda-activated').not($currentFocusedElement).each(function() {
            var $relatedBlock = $(this).attr('data-related-block') ? $($(this).attr('data-related-block')) : null;

            if($relatedBlock && $relatedBlock.length && $relatedBlock.has($checkEl).length) {
                $parent = $(this);
            }
        });

        if($parent && $parent.length) {
            $parent.focus();
        } else {
            // find next active element
            $checkEl.nextAll().each(function() {
                if(
                    $(this).is('a, button, a, button, input, select, textarea') ||
                    (typeof $(this).attr('tabindex') !== 'undefined' && $(this).attr('tabindex') > -1)
                ) {
                    $nextElement = $(this);

                    return false;
                }
            });

            if($nextElement && $nextElement.length) {
                $nextElement.focus();
            }
        }

    }

    function setTabIndexToElements($elements, tabindex) {
        $elements.each(function(k, el) {
            if(typeof $(el).attr('data-tabindex-init') === 'undefined' || $(el).attr('data-tabindex-init') === '') {
                $(el).attr('data-tabindex-init', $(el).attr('tabindex') || 0);
            }
            $(el).attr('tabindex', tabindex);
        });
    }

    function restoreTabindexToElements($elements) {
        $elements.each(function(k, el) {
            $(el).attr('tabindex', $(el).attr('data-tabindex-init'));
        });
    }

    // Handling one-by-one banner displaying
    (function prioritizeBannerDisplay(silent){ // re-call is possible, check 'window' attached event below

        var $priorityBasedBanners = $('.jsBannerPriorityBased');
        var priorities = [];

        $priorityBasedBanners
            .each(function(i, el){
                var $banner = $(el);
                var bannerPriority = $banner.data('banner-priority');
                if (bannerPriority !== undefined && $banner.is(':visible') && $banner.height()){
                    priorities.push(parseInt(bannerPriority));
                    $banner
                        .hide()
                        .on('showNextPriorityBanner', showNextPriorityBanner);

                }
            });

        var sortedPriorities = priorities.sort();

        function showNextPriorityBanner(){
            if (sortedPriorities.length){
                $priorityBasedBanners.filter('[data-banner-priority="' + sortedPriorities[0] +  '"]').show();
                sortedPriorities.shift();
            }
        }

        showNextPriorityBanner();

        if (! silent) {
            $(window).on('postponed-adding-to-top-banner-priority', function(e, $banner){
                prioritizeBannerDisplay(true);
            });
        }

    })();


	(function apadtLangDependableImage() {
		var $images = $('.langDependableSrc');
		$images.each(function(i, el){
			IMAGE_UTILS.apadtLangDependableImage($(el));
		});
	})();

    (function retinizeBackgroundImage() {
        var $images = $('.retinize');
        $images.each(function(i, el){
            IMAGE_UTILS.retinizeBgImage($(el));
        });
    })();

});

function setPreselectedInfo($el, text) {
    $el.length && $el.attr('aria-label', text);
}

// AODA Preselected TVGUSA-51952
function aodaPrepareCustomSelect($button) {
    var $ul = $button.nextAll('.dropdown-menu:first'),
        $text = $button.find('.value'),
        $select = $button.nextAll('select:first'),
        textId = $button.attr('id') + 'PseudoText',
        selectId = $select.attr('id'),
        ulId = $ul.attr('id');

    if(!ulId) {
        ulId = $button.attr('id') + 'PseudoUl';
        $ul.attr('id', ulId);
    }
    if(!selectId) {
        selectId = $button.attr('id') + 'RelatedSelect';
        $select.attr('id', selectId);
    }

    $text.attr('id', textId);
    $button
        .attr('aria-describedby', textId)
        .attr('data-related-block', '#'+ulId)
        .attr('data-onpressenter-trigger', 'none')
        .attr('role', Surroundings.isChrome() ? 'list' : 'combobox');
}

function aodaPrepareTabSwitch($tabSwitch) {
    $tabSwitch.find('a')
        .off('shown.bs.tab.aodaPreselection')
        .on('shown.bs.tab.aodaPreselection', function() {
            $(this).attr('aria-selected', 'true');
            $(this).closest('li').siblings().eq(0).find('a').attr('aria-selected', 'false');
        });

    $tabSwitch.find('li.active a').trigger('shown.bs.tab.aodaPreselection');
}

function aodaPrepareComboboxElementsDescr($elements, closestSelector, labelSelector) {
    $elements.each(function() {
        var $el = $(this),
            $relatedLabel = $el.closest(closestSelector).find(labelSelector),
            labelId = $relatedLabel.attr('id') || '',
            elId = $el.attr('id') || '';

        if($relatedLabel.length) {
            if(!elId) {
                elId = ('pseudoCombobox' + Math.floor(Math.random()*1000)*Math.floor(Math.random()*9584));
                $el.attr('id', elId);
            }
            if(!labelId) {
                labelId = elId+'LabelElement';
                $relatedLabel.attr('id', labelId);
            }
            $el.attr('aria-labelledby', labelId);
        }
    });
}


function handleResponse(xhr) {
    if(xhr.responseJSON && typeof xhr.responseJSON === 'object') { // json response

    } else if(xhr.responseType === 'blob') {

    } else if(xhr.responseText && !isJson(xhr.responseText)) { // text response
        // check Session expired page
        var sessionExpirationOccured = checkSessionExpirationByResponse(xhr.responseText);

        if(sessionExpirationOccured) {
            $('#ajaxSessionTimeoutWarningModal').appendTo($('body')); // TVGUSA-35082

            // prevent other handlers
            xhr.abort();
            throw 'Session expired. Other handlers are canceled.';
        }
    }
}

function checkSessionExpirationByResponse(response) {
    var sessionExpiredId = '#sessionExpiredTitle',
        loginPageId = '#authArea';

    var sessionExpirationOccured = false;

    try {
        sessionExpirationOccured = $(response).find(sessionExpiredId).length || $(response).find(loginPageId).length;
    }
    catch (e) {
        // Possible different format type than text/html like javascript
    }

    return sessionExpirationOccured;
}

function isJson(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

function disableWindowScroll(setScrollTop) {
    if (Surroundings.isIOS()) {
        if (setScrollTop) return document.body.dataset.scrollTop = document.body.scrollTop;

        var scrollPosition = document.body.dataset.scrollTop;

        document.body.classList.add('ios-position-fixed');
        $('body').css({'top': -scrollPosition})
    }
}

function restoreWindowScroll(){
    if (Surroundings.isIOS()) {
        $('body').css({'top': ''});
        document.body.scrollTop = +document.body.dataset.scrollTop;
        document.body.classList.remove('ios-position-fixed');
    }
}

function formatDate(date) {
    var
        arr = date.split(/[- :T]/),
        _date = new Date(arr[0], arr[1] - 1, arr[2], arr[3], arr[4], 0);
    return _date.toString("MMMM");
}

function createDateAsUTC(date) {
    return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds()));
}

function convertDateToUTC(date) {
    return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());
}

function getUrlVars(hashOnly) {

    var
        vars = [],
        hash,
        hashStart = window.location.href.indexOf('#'),
        hashUsed = hashOnly && hashStart !== -1;

    if (hashOnly && ! hashUsed) {
        return vars;
    }

    var sourceDataStr = hashUsed
        ? window.location.href.substring(hashStart + 1)
        : window.location.href;


    if (! hashOnly && hashStart !== -1) {
        sourceDataStr = sourceDataStr.substring(sourceDataStr.indexOf('?') + 1, sourceDataStr.indexOf('#'));
    }

    var hashes = sourceDataStr.slice(sourceDataStr.indexOf('?') + 1).split('&');
    for (var i = 0; i < hashes.length; i++) {
        hash = hashes[i].split('=');
        vars.push(hash[0]);
        vars[hash[0]] = hash[1];
    }
    return vars;
}

function getUrlParams(url, hashOnly) { // gets url and return object like {key:value}

    var
        params = {},
        hash,
        hashStart = url.indexOf('#'),
        hashUsed = hashOnly && hashStart !== -1;

    if (hashOnly && ! hashUsed) {
        return params;
    }

    var sourceDataStr = hashUsed
        ? url.substring(hashStart + 1)
        : url;


    if (! hashOnly && hashStart !== -1) {
        sourceDataStr = sourceDataStr.substring(sourceDataStr.indexOf('?') + 1, sourceDataStr.indexOf('#'));
    }

    var hashes = sourceDataStr.slice(sourceDataStr.indexOf('?') + 1).split('&');
    for (var i = 0; i < hashes.length; i++) {
        hash = hashes[i].split('=');
        params[hash[0]] = hash[1];
    }

    return params;
}


function bindCmsEdit(context) {
    var edit;
    if (context === undefined) {
        edit = $('.cms_content');
    } else {
        edit = $('.cms_content', context);
    }
    edit.each(function (i, o) {
        if ($(o).children('[data-ignore-cms-decoration]').length) return;
        var editButton = $(this).children('.cms_edit');
        var editWrapper = editButton.next();
        if (editWrapper.length === 0) {
            editWrapper = $('<div />');
            $(o).append(editWrapper);
        }
        editWrapper.addClass('cms_wrapper');
        $("<div class='cms_marker_top' />").appendTo(editWrapper);
        $("<div class='cms_marker_bottom' />").appendTo(editWrapper);
        $("<div class='cms_marker_right' />").appendTo(editWrapper);
        $("<div class='cms_marker_left' />").appendTo(editWrapper);
        editButton.appendTo(editWrapper);
    });
    edit.find('.cms_edit').bind('click', function () {
        var anchor = $(this);
        window.open(anchor.attr("href"), 'name', 'height=760,width=940,scrollbars=yes');
        return false;
    });
}

function isInViewport(elem) {
    var $elem = $(elem);
    var $window = $(window);

    var docViewTop = $window.scrollTop();
    var docViewBottom = docViewTop + $window.height();

    var elemTop = $elem.offset().top;
    var elemBottom = elemTop + $elem.height();

    return ((elemBottom <= docViewBottom) && (elemTop >= docViewTop));
}

var Viewport = {
    isScrolledPast: function(elem) {
        var
            $elem = $(elem),
            $window = $(window),
            docViewTop = $window.scrollTop(),
            elemTop = $elem.offset().top;
        return elemTop < docViewTop;
    }
};

var ValFormat = {
    number: function (num, decimals) {
        if(decimals) {
            num = parseFloat(num).toFixed(2);
        }
        var delimiter = ',';
        if(isLanguageFrCa()) {
            delimiter = ' ';
        }

        return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1" + delimiter);
    },
    phone: function (phone, separator) {
        separator = separator || '.';
        phone = phone.replace(/[^\/\d]/g,'').replace(/[^0-9]/g, separator);
        var pattern = '($1) ' + ['$2', '$3'].join(separator);
        phone = phone.replace(/(\d{3})(\d{3})(\d{4})/, pattern); // eg. "($1) $2-$3"
        return phone;
    },
    hideEmail: function (email, numberOfAsteriks) {
      if (email) {
      var
        numberOfAsteriks = numberOfAsteriks || 4,
        trimedEmail = email.split('@'),
        emailName = trimedEmail[0].split(''),
        hiddenEmailName = emailName[0] + Array(numberOfAsteriks + 1).join('*') + emailName[emailName.length - 1];

      return hiddenEmailName + '@' + trimedEmail[1];
      }

      return ''
    },
    date: function(dateString, initialDateStringFormat, customDateFormat) {
        if (! dateString) {
            return '';
        }
	    initialDateStringFormat = initialDateStringFormat || 'MM DD YYYY';
        return moment(dateString, initialDateStringFormat).format(customDateFormat || DATE_FORMAT.toUpperCase());
    }
};

if(!Surroundings.isChallengesPortal()) {
    window.addCustomXMLRequestCallback = function (callback) {
        var oldSend, i;
        if (XMLHttpRequest.callbacks) {
            // we've already overridden send() so just add the callback
            XMLHttpRequest.callbacks.push(callback);
        } else {
            // create a callback queue
            XMLHttpRequest.callbacks = [callback];
            // store the native send()
            oldSend = XMLHttpRequest.prototype.send;
            // override the native send()
            XMLHttpRequest.prototype.send = function () {
                if (this.onload) {
                    this._onload = this.onload;
                }

                this.onload = function () {
                    if (this.readyState === 4) {
                        for (i = 0; i < XMLHttpRequest.callbacks.length; i++) {
                            XMLHttpRequest.callbacks[i](this);
                        }
                    }

                    if (this._onload) {
                        return this._onload.apply(this, arguments);
                    }
                };

                // call the native send()
                return oldSend.apply(this, arguments);
            }
        }
    }
}

var HW = {
    adaptLink: function (sourceLink) {

        if(typeof(prepareSearchResultLink) === 'undefined'){
            var part;

            if(sourceLink.indexOf("//")===0){
                sourceLink = sourceLink.replace("//", "/");
            }

            //HW
            var match = sourceLink.match(/(.*KnowledgeContent\/)(.*)(\/?\?.*)/);
            if (match && match.length >= 2) {
                part = match[2];
                return CONTEXT_PATH + "/KnowledgeContent/" + part;
            }

            //Test
            if(sourceLink.indexOf('integrationtest.powerofvitality') > -1){
                return sourceLink.replace('integrationtest.powerofvitality', 'powerofvitality');
            }

        }else{
            return prepareSearchResultLink(sourceLink);
        }
        return sourceLink;

    }
}

function ucwords( str ) {	// Uppercase the first character of each word in a string
    return str.replace(/^(.)|\s(.)/g, function ( $1 ) { return $1.toUpperCase ( ); } );
}

function ucfirst( str, toLowerCase ) {	// Uppercase the first character of string
    var f = str.charAt(0).toUpperCase();
    if(toLowerCase) {
        str = str.toLowerCase();
    }

    return f + str.substr(1, str.length-1);
}

function checkIsIE() {
    var ua = window.navigator.userAgent;
    var msie = ua.indexOf("MSIE ");

    if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) { // If Internet Explorer, return version number
        return true;
    } else {
        return false;
    }
}

$.fn.isIE = function () {
    return (function () {
        var div, i, v;
        v = 3;
        div = document.createElement('div');
        for (i = 1; i <= 12; ++i) {
            div.innerHTML = '<!--[if gt IE ' + (++v) + ']><i></i><![endif]-->';
            if (!div.getElementsByTagName('i').length) {
                break;
            }
        }
        if (v > 4) {
            return v;
        } else if (!!navigator.userAgent.match(/Trident.*rv\:11\./)) {
            return 11;
        } else {
            return '';
        }
    })();
};

$(document.documentElement).addClass(($.fn.isIE()) ? ('ie') + $.fn.isIE() : '');

$.fn.hasAncestor = function(selectorOr$Scope) {
    return this.filter(function() {
        return !! $(this).closest(selectorOr$Scope).length;
    });
};

$.fn.setCursorPosition = function(pos) {
    this.each(function(index, elem) {
        if (elem.setSelectionRange) {
            elem.setSelectionRange(pos, pos);
        } else if (elem.createTextRange) {
            var range = elem.createTextRange();
            range.collapse(true);
            range.moveEnd('character', pos);
            range.moveStart('character', pos);
            range.select();
        }
    });
    return this;
};

$.fn.refineFormPlaceholders = function (sticky) {
    var isIE = $.fn.isIE();
    var myNav = navigator.userAgent.toLowerCase();
    var isAndroidNative = ((myNav.indexOf('mozilla/5.0') > -1 && myNav.indexOf('android ') > -1 && myNav.indexOf('applewebkit') > -1) && !(myNav.indexOf('chrome') > -1));
    var androidVersion = parseFloat(myNav.slice(myNav.indexOf("android")+8));
    if ((isIE && isIE <= 11) || (isAndroidNative && androidVersion < 4.2)) {
        return window.setTimeout($.proxy(function () {
            return $('input:text[placeholder], input[type=email][placeholder], input[type=password][placeholder], textarea[placeholder]', this).each(function (i, el) {
                var $input, phClass, text;
                $input = $(el);
                text = $input.attr('placeholder');
                phClass = 'withPlaceholder';
                if ($input.val() === '') {
                    ($input.val(text)).addClass(phClass);
                    if($input.attr('type') === 'password') {
                        $input.attr('type', 'text');
                        $input.attr('data-init-type', 'password');
                    }
                    $input.attr('spellcheck', 'false'); // TVGUSA-51382
                }
                $input.on('focus', function(e){
                    if ($input.val() === text) {
                        e.preventDefault();
                        $input.setCursorPosition(0);
                    }
                }).on('keydown', function (e) {
                    var $el;
                    $el = $(e.currentTarget);
                    if ($el.val() === $el.attr('placeholder')) {
                        if (!sticky && $el.hasClass(phClass)) {
                            $input.attr('data-init-type') === 'password' && $input.attr('type', 'password');
                            $input.attr('spellcheck', 'true');
                            return ($el.val('')).removeClass(phClass);
                        } else {
                            $el.trigger('change');
                            $input.attr('data-init-type') === 'password' && $input.attr('type', 'password');
                            $input.attr('spellcheck', 'true');
                            return $el.removeClass(phClass);
                        }
                    }
                }).on('keyup', function () {
                    if ($input.val() === '') {
                        $input.val(text).addClass(phClass);
                        $input.attr('data-init-type') === 'password' && $input.attr('type', 'text');
                        $input.setCursorPosition(0);
                        $input.attr('spellcheck', 'false');
                    }
                }).on('change', function () { // TVGUSA-44283
                    if ($input.val() !== '') {
                        $input.removeClass(phClass);
                        $input.attr('data-init-type') === 'password' && $input.attr('type', 'password');
                        $input.attr('spellcheck', 'true');
                    }
                });
                return $input.on('blur', function (e) {
                    var $el;
                    $el = $(e.currentTarget);
                    if ($el.val() === '') {
                        $input.attr('spellcheck', 'false');
                        $el.val($el.attr('placeholder')).addClass(phClass);
                        $input.attr('data-init-type') === 'password' && $input.attr('type', 'text');
                    }
                });
            });
        }, this), 300);
    }
};

$.fn.refineFormTriggers = function () {
    var _this = this;
    this.find('.formTrigger').each(function () {
        var $row;
        $row = $(this);
        $row.filter('.formTriggerRadio').each(function () {
            var $el, $input, $trigger, isChecked;
            $el = $(this);
            $trigger = $el.find('span.trigger');
            $input = $el.find('input');
            isChecked = $input.is(':checked') ? true : false;
            $el.toggleClass('checked', isChecked);
            $trigger.on('click', function () {
                var cont;
                cont = $input.is(':checked') ? false : true;
                if (!cont) {
                    return;
                }
                $input.prop('checked', cont);
                return $input.trigger('change');
            });
            return $input.on('change', function () {
                var _$el;
                _$el = $(this);
                isChecked = _$el.is(':checked') ? true : false;
                $("input[name=" + ($input.attr('name')) + "]", _this).each(function () {
                    var __$el;
                    __$el = $(this);
                    __$el.closest('.formTrigger').removeClass('checked');
                    return __$el.prop('checked', false);
                });
                _$el.prop('checked', isChecked);
                return $el.toggleClass('checked', isChecked);
            });
        });
        return $row.filter('.formTriggerCheckbox').each(function () {
            var $el, $input, $trigger, isChecked, isDisabled;
            $el = $(this);
            $trigger = $el.find('span.trigger');
            $input = $el.find('input');
            isChecked = $input.is(':checked') ? true : false;
            isDisabled = $input.is(':disabled') ? true : false;
            $el.toggleClass('checked', isChecked);
            $el.toggleClass('disabled', isDisabled);
            $trigger.on('click', function () {
                $input.prop('checked', $input.is(':checked') ? false : true);
                return $input.trigger('change');
            });
            return $input.on('change', function () {
                var _$el;
                _$el = $(this);
                isChecked = _$el.is(':checked') ? true : false;
                _$el.prop('checked', isChecked);
                return $el.toggleClass('checked', isChecked);
            });
        });
    });
    return this;
};


$.fn.refineFormSingleChoiceBoxes = function(){
    var selector = 'input[data-form-field-single-choice="true"]';
    var $els = this.find(selector);
    if (! $els.length) {
        this.filter(selector);
    }
    if (! $els.length) {
        return this;
    }

    $els.on('change', function(e){
        var $triggerEl = $(e.currentTarget);
        var name = $triggerEl.attr('name');
        var $filtered = $els.filter('[name="' + name + '"]').filter(function(i, el){
            var $el = $(el);
            return ! $el.is($triggerEl);

        });
        $filtered.prop('checked', false);
    });

    return this;
};

$.fn.extractFormValues = function (withCustomIds) {
    var data, keyVector;
    data = {};
    keyVector = withCustomIds ? 'formrow-custom-id' : 'formrow-id';
    this.find(':input:not(:button)').each(function (i, el) {
        var $el, idKey, isChecked, val;
        $el = $(el);
        if ($el.attr('type') === 'radio' && ($el.attr('name'))) {
            isChecked = $el.closest('.formRow').find(':input[type=radio][name=' + ($el.attr('name')) + ']:checked').length;
            if (!isChecked) {
                data[$el.data(keyVector)] = '';
            }
            if ($el.is(':not(:checked)')) {
                return;
            }
        }
        val = $.trim($el.val());
        idKey = $el.data(keyVector);
        if (!data[idKey]) {
            return data[idKey] = val;
        }
    });
    return data;
};

$.fn.shimBootstrapDropDown = function (cb, context, tag) {
    var
        _self = this,
        $domNativeTrigger = $('#' + this.data('shim-bootstrap-dropdown'), context),
        $domValueTrigger = $('#' + this.attr('aria-labelledby'), context),
        tag = tag || 'a',
        $dropdown;

    this.on('keydown', function (e) {
        if (e.keyCode === 9) setTimeout(function () {
            $(this).dropdown('toggle')
        }, 0)
    })
        $dropdown = this.parent('.dropdown');

    this.find(tag).on('click', function (e) {
        var
            $currentTarget = $(e.currentTarget),
            navigating = $currentTarget.is('[data-navigating="true"]');

        if (navigating) {
            // no event prevention here;
        }
        else {
            e.preventDefault();
        }
        $domNativeTrigger
            .prop('selectedIndex', $(e.currentTarget).data('indx'))
            .trigger('change');

    }.bind(this));

    this.parent().on('keyup', function (e) {
        var key = e.which || e.keyCode || 0,
            input = String.fromCharCode(key).toLowerCase(),
            output = $domNativeTrigger.find('option'),
            selectedOption = $domNativeTrigger.find('option:selected'),
            selectedOptionIndex = selectedOption.length > 0 ? selectedOption[0].index : 0,
            startIndex = selectedOption.length > 0 && selectedOption[0].text.substr(0, 1).toLowerCase() === input ? selectedOptionIndex + 1 : 0;

        if(startIndex >= output.length){
            startIndex = 0;
        }

        var isIndexReset = false;
        for(var i= startIndex; i < output.length; i++) {
            var firstLetter = output[i].text.substr(0, 1).toLowerCase();
            if(firstLetter === input){
                output[i].selected = true;
                $domNativeTrigger.trigger('change');
                break;
            }

            //reset Index
            if(!isIndexReset && i === output.length - 1){
                i = -1;
                isIndexReset = true;
            }
        }
    });

    $dropdown
        .on('shown.bs.dropdown', function() {
            $(this).find('.dropdown-menu li.selected > a').focus()
        })
        .on('hide.bs.dropdown', function(e) {
            if (e && e.relatedTarget) {
                var $eventRelatedTarget = $(e.relatedTarget);
                if ($eventRelatedTarget.is('[data-aoda-dynamic-operable-postblurfocus="self"]')) {
                    $eventRelatedTarget.focus();
                }
            }
        });

    $domNativeTrigger.on((Surroundings.isFF() ? 'keyup ' : '') + 'change', function (e) {
        var index = $(e.currentTarget).find('option:selected').index();
        $domValueTrigger.find('.value').html(
            _self.find('a[data-indx="' + index + '"]').html()
        );
        setSelected($domNativeTrigger.prop('selectedIndex'));
        if (cb) {
            cb.apply(this, arguments);
        }
    });

    function setSelected(id) {
        _self.find('li').removeClass('selected');
        _self.find(tag + '[data-indx="' + id + '"]').closest('li').addClass('selected');
        _self.find(tag + '[data-indx="' + id + '"]').focus();
    }

    setSelected($domNativeTrigger.prop('selectedIndex'));

    if (Surroundings.isTouchDevice && this.data('shim-bootstrap-dropdown-native-on-touch')){
        this.closest('.dropdown').addClass('basicSelectDefaultDropdown');
    }


    return this;
};

$.fn.shimBootstrapDropDownResizable = function (cb, context) {

    this.shimBootstrapDropDown(cb, context);

    var // reused
        resize,
        _self = this,
        $domDropdown = _self.closest('.dropdown'),
        $domMenuPseudo = this,
        $domMenuTrigger = $('#' + this.attr('aria-labelledby'), context);

    (resize = function () {
        var
            $domValue = $domMenuTrigger.find('.value'),
            recoveryVal = $domValue.html(),
            id = 0,
            max = 0;

        $domDropdown.addClass('open').removeClass('processed')

        $domValue.html($domMenuPseudo.find('li').each(function (i) {
            var _newmax = Math.max(max, $(this).text().length);
            if (_newmax > max) {
                max = _newmax
                id = i
            }
        }).eq(id).find('a').html());

        $domDropdown.width('auto');
        $domMenuPseudo.width('auto');

        var maxWidth = Math.max($domDropdown.width(), $domMenuPseudo.width());
        if (maxWidth) {
            $domDropdown.width(maxWidth);
            $domMenuPseudo.width(maxWidth);
        }
        $domValue.html(recoveryVal);
        $domDropdown.removeClass('open').addClass('processed');
    })();

    $(window).on('resize', resize);

    this.on('update-size', resize);

    return this;
};

$.fn.generateID = function (len) {
    var i, pool, text;
    len = len || 12;
    text = '';
    pool = 'abcdefghijklmnopqrstuvwxyz0123456789';
    for (i = 0; 0 <= len ? i < len : i > len; 0 <= len ? ++i : --i) {
        text += pool.charAt(Math.floor(Math.random() * pool.length));
    }
    return text;
};

$.fn.scrollToFocused = function (options) {
    options = options || {};
    var
        whatToScrollSelector = options.whatToScrollSelector || 'html, body',
        speed = options.speed || 760,
        topOffset = options.topOffset || 0,
        callback = options.callback || function () { };
    return $(whatToScrollSelector).stop().animate({scrollTop: (this.offset().top + topOffset)}, speed, 'easeInOutCubic', callback).promise();
};

$.fn.focusedInlineCardOrder = function (inlineElement, lastFocusedCard) {
    var onBlurHandler = function (e) {
        if (! $(inlineElement).find(e.relatedTarget).length) {
            lastFocusedCard.focus()
        }
    };

    var onClickHandler = function () {
        setTimeout(function () {
            $(this).off('blur', onBlurHandler).off('click', onClickHandler);
        }.bind(this), 300)
    };

    $(inlineElement).find('.closeCtrl')
        .on('blur', onBlurHandler)
        .on('click', onClickHandler)
};

$.fn.forceNumericOnly = function (skipKeyCode) {
    return this.each(function () {
        var checkKeyCode = function(key, ctrlKey) {
            if (skipKeyCode && key === skipKeyCode) {
                return true;
            }

            if((key === 8 || key === 9 || key === 13 || key === 46 || key === 127 || (key >= 48 && key <= 57) || (ctrlKey && (key === 99 || key === 118 || key === 120 || key === 122)))){
                return true;
            }

            return false;
        };

        return $(this).on('keypress', function (e) {
            var key = e.charCode || e.keyCode || 0;

            if(!checkKeyCode(key, e.ctrlKey)) {
                e.preventDefault();
            }

        }).on('keyup blur', function(e) {
            var key = e.charCode || e.keyCode || 0,
                val = $(this).val(),
                charCode,
                newValue = '';

            if (key == 0 || key == 229) { //for android chrome keycode fix
                if(isNaN($(this).val())) {
                    for(var i=0; i < val.length; i++) {
                        charCode = val.charCodeAt(i);
                        if(checkKeyCode(charCode)) {
                            newValue += val[i];
                        }
                    }
                    if(newValue != val) {
                        $(this).val(newValue).trigger('change');
                    }
                }
            }
        });
    });
};

$.fn.changeAmmountInputValue = function () {
    var $el, val, _val;
    $el = this;
    var minValue = $el.data('min-value');
    var maxValue = $el.data('max-value');
    var defaultValue = $el.data('default-value');
    $el.on('keyup', function () {
        //double check to have only numbers, as the forceNumericOnly function doesn't work properly on android
        $el.val($el.val().replace(/\D/g, ''));
        val = parseInt(_val = $.trim($el.val()));
        if (_val === '' && minValue !== undefined) {
            $el.val(minValue);
        } else if (val > maxValue && maxValue !== undefined) {
            $el.val(maxValue);
        } else if (val < minValue && minValue !== undefined) {
            $el.val(minValue);
        } else if (val || val === 0) {
            $el.val(val);
        }
        if(_.isNaN($el.val())){
            $el.val('');
        }

    });
    $el.on('blur', function () {
        //double check to have only numbers, as the forceNumericOnly function doesn't work properly on android
        $el.val($el.val().replace(/\D/g, ''));

        val = parseInt(_val = $.trim($el.val()));
        if ((_val === '' || val === 0 || _.isNaN(_val))) {
            if(defaultValue !== undefined){
                $el.val(defaultValue);
                $el.trigger('change');
            } else {
                $el.val('');
                $el.trigger('change');
            }
        }
    });

    return this;
};

$.fn.splitArrayIntoColumns = function (a, n) {
    var len = a.length, out = [], i = 0;
    while (i < len) {
        var size = Math.ceil((len - i) / n--);
        out.push(a.slice(i, i += size));
    }
    return out;
}

$.fn.attachPopOver = function ($parameter, $parameterOffsetLeft, $parameterOffsetRight) {
    if($(this).data('bs.popover') && $(this).data('bs.popover').enabled) {
        return;
    }

    var
        $this = this,
        $window = $(window),
        $body = $('body'),
        gutter = 20;

    $parameter = $parameter || '#popover-wrap';
    $parameterOffsetLeft = $parameterOffsetLeft || 0;
    $parameterOffsetRight = $parameterOffsetRight || 0;

    this.popover({
        trigger: 'manual',
        container: $parameter,
        toggle: 'popover',
        placement: function (tip, element) {
            var $element = $(element),
                elementOffset = $element.offset(),
                elementTop = elementOffset.top - $(window).scrollTop(),
                $tip = $(tip),
                $tempTip = $tip.clone().css({
                    'display': 'block',
                    'visibility': 'hidden',
                    'position': 'absolute'
                }).insertAfter($body),
                tipHeight = $tempTip.outerHeight();

            $tempTip.remove();

            if (elementTop - tipHeight < gutter || elementTop - tipHeight < ($('#menu').offset().top + $('#menu').outerHeight(true))) {
                return 'bottom';
            }

            return 'top';
        },
        html: 'true',
        template: '<div class="popover popoverInfo" role="tooltip"><div class="arrow"></div><div class="popover-content"></div></div>'
    });

    $(this).addClass('popoverAttached');

    var hidePopOver = function () {
        $this.popover('hide').removeClass('hover').removeClass('clicked');
    };

    var hidePopOverOnOutsideClick = function (e) {
        if (!$this.is(e.target) && $this.has(e.target).length === 0 && $('.popover').has(e.target).length === 0) {
            hidePopOver();
        }
    };

    var fixPosition = function () {
        var movePosition = function(tip, pixels){
            tip.css('left', parseInt(tip.css('left')) + pixels + 'px');
        };
        if($this.data('bs.popover').tip() && $this.data('bs.popover').tip()[0]
            && $this.data('bs.popover').arrow() && $this.data('bs.popover').arrow()[0]
            && $this.data('bs.popover').$element && $this.data('bs.popover').$element[0]){
            var tip = $this.data('bs.popover').tip();
            var arrow = $this.data('bs.popover').arrow();
            var icon = $this.data('bs.popover').$element;
            var iconRect = icon[0].getBoundingClientRect();
            if(tip[0].getBoundingClientRect().right > $(window).width()){
                movePosition(tip, - (tip[0].getBoundingClientRect().right - $(window).width()));
            }
            if(tip[0].getBoundingClientRect().right < iconRect.right || tip[0].getBoundingClientRect().left > iconRect.left){
                movePosition(tip, - (tip[0].getBoundingClientRect().right - iconRect.right));
            }

            if(arrow[0].getBoundingClientRect().right > tip[0].getBoundingClientRect().right){
                arrow.css('left', (tip[0].getBoundingClientRect().width - arrow[0].getBoundingClientRect().width - 4) + 'px');
            }
            if(arrow[0].getBoundingClientRect().left !== iconRect.left){
                arrow.css('left', (tip[0].getBoundingClientRect().width - arrow[0].getBoundingClientRect().width - 4) + 'px');
                movePosition(arrow, - (arrow[0].getBoundingClientRect().left - iconRect.left + 1));
            }
        } else {
            $this.data('bs.popover').tip().css('left', parseInt($this.data('bs.popover').tip().css('left')) + $parameterOffsetLeft + 'px');
        }
    };

    var updatePopOver = function () {
        hidePopOver();
    };

    var hideOnAnotherPopoverHover = function (e, el) {
        if (!$this.is(el)) {
            hidePopOver();
        }
    };

    if (Surroundings.isTouchDevice) {
        this.on('click', function () {
            var isShown = $this.hasClass('hover');
            $this.popover(isShown ? 'hide' : 'show').toggleClass('hover', !isShown);
            !isShown && fixPosition();
        });
    } else {
        this.hover(
            function () {
                if (!$this.hasClass('clicked')) {
                    if (!$this.hasClass('hover')) {
                        $this.popover('show');
                        $this.addClass('hover');
                    } else {
                        $this.one('hidden.bs.popover', function() {
                            $this.popover('show');
                            $this.addClass('hover');
                        })
                    }
                    fixPosition();
                }
                var event = $.Event('popover-hover');
                $body.trigger(event, $this);
            },
            function () {
                if (!$this.hasClass('clicked')) {
                    $this.popover('hide');
                    $this.one('hidden.bs.popover', function() {
                        $this.removeClass('hover')
                    })
                }
            }
        );

        this.on('click', function () {
            var isShown = $this.hasClass('hover'),
                isClick = $this.hasClass('clicked');

            if (isShown && !isClick) {
                $this.addClass('clicked');
            }
            else {
                $this.popover(isShown && isClick ? 'hide' : 'show').toggleClass('hover', !isShown || !isClick).toggleClass('clicked', !isClick);
                if (!(isShown && isClick)) {
                    fixPosition();
                }
            }
        });

        this.on('focus', function () {
            var isShown = $this.hasClass('hover');
            if(!isShown) {
                $this.popover('show').toggleClass('hover', !isShown);
                fixPosition();
            }
        }).on('blur', function () {
            $this.popover('hide').removeClass('hover');
        });

    }

    $body.on('click', hidePopOverOnOutsideClick);
    $body.on('popover-hover', hideOnAnotherPopoverHover);

    $window.on('orientationchange', hidePopOver);
    $window.on('resize', updatePopOver);

    this.hidePopOver = hidePopOver;

    return this;
};

$.fn.ellipsisize = function () {
    return this.each(function () {
        var
            $this = $(this),
            $copyOfOriginalDom,
            samplefillText = ($this.text().replace(/[\n\r]+/g, "<br>") || 'MMMMMM').substring(0, 5);

        $this.data('orig-text', $this.html());

        function applyEllipses(e) {
            var origHtml = $this.data('orig-text'),
                isCollapsed = $this.hasClass('ellipsisizedCollapsed'),
                isEllipsisized = $this.hasClass('ellipsisized');

            $this.removeClass('ellipsisized ellipsisizedCollapsed');
            $this.html(origHtml);
            $copyOfOriginalDom = $this.clone();
            var needToMakeEllipsis = $this.height() > $this.text(samplefillText).height();
            if (!needToMakeEllipsis) {
                $this.replaceWith($copyOfOriginalDom);
            } else {
                var
                    moreText = $this.data('plugin-ellipsisize-more-text') || 'More',
                    lessText = $this.data('plugin-ellipsisize-less-text') || 'Less',
                    prefixText = $this.data('plugin-ellipsisize-prefix-text') || '&nbsp;',
                    handleHeightChange = $this.data('plugin-handle-height-change') || false,
                    $fadeHelperTmpl = $('<span class="ellipsisizedFadeHelper" />'),
                    $moreCtrlTmpl = $('<span class="ellipsisizedMoreCtrl" tabindex="0" role="button">' + moreText + '</span>'),
                    $lessCtrlTmpl = $('<span class="ellipsisizedLessCtrl" tabindex="0" role="button" aria-label="'+lessText+'">' + lessText + '</span>'),
                    $prefixTmpl = $('<span class="ellipsisizedPrefixCtrl">' + prefixText + '</span>');

                $copyOfOriginalDom
                    .append($fadeHelperTmpl, $prefixTmpl, $moreCtrlTmpl, $lessCtrlTmpl)
                    .addClass('ellipsisized');

                $lessCtrlTmpl.wrap('<span aria-live="polite"></span>');

                $copyOfOriginalDom.toggleClass('ellipsisizedCollapsed', isCollapsed || !(e && e.type === 'resize') || !isEllipsisized);

                $moreCtrlTmpl.on('click', function () {
                    $copyOfOriginalDom.removeClass('ellipsisizedCollapsed');
                    $lessCtrlTmpl.focus();
                    if (!! handleHeightChange) $(window).trigger('globalHeight:changed');
                });

                $lessCtrlTmpl.on('click', function () {
                    $copyOfOriginalDom.addClass('ellipsisizedCollapsed');
                    $moreCtrlTmpl.focus();
                    if (!! handleHeightChange) $(window).trigger('globalHeight:changed');
                });

                $moreCtrlTmpl.on('keydown', function (e) {
                    if(e.keyCode === 13) {
                        $copyOfOriginalDom.removeClass('ellipsisizedCollapsed');
                        $lessCtrlTmpl.focus();
                    }
                });

                $lessCtrlTmpl.on('keydown', function (e) {
                    if(e.keyCode === 13) {
                        $copyOfOriginalDom.addClass('ellipsisizedCollapsed');
                        $moreCtrlTmpl.focus();
                    }
                });


                $this.replaceWith($copyOfOriginalDom);
            }
            $this = $copyOfOriginalDom;
            $this.data('orig-text', origHtml);
        }

        applyEllipses();
        $(window).on('resize', applyEllipses);
        return this;
    });
};

$.fn.ellipsisizeSimple = function(options){
    options = options || {};
    var
        maxChars = options.maxChars || 0,
        cb = options.callback;

    return this.each(function () {
        var
            $this = $(this),
            $etalonDom = $this.clone(),
            $ellipsisizedDom = $this.clone(),
            maxChars = $this.data('ellipsisize-max-chars') || maxChars,
            origText = $this.text(),
            ellipsisizedText;

        if (origText.length > maxChars) {
            var
                moreText = $this.data('plugin-ellipsisize-more-text') || 'More',
                lessText = $this.data('plugin-ellipsisize-less-text') || 'Less',
                prefixText = $this.data('plugin-ellipsisize-prefix-text') || '...',
                $fadeHelperTmpl = $('<span class="ellipsisizedFadeHelper" />'),
                $moreCtrlTmpl = $('<span class="ellipsisizedMoreCtrl">' + moreText + '</span>'),
                $lessCtrlTmpl = $('<span class="ellipsisizedLessCtrl">' + lessText + '</span>'),
                $prefixTmpl = $('<span class="ellipsisizedPrefixCtrl">' + prefixText + '</span>');

            ellipsisizedText = origText.substring(0, maxChars);

            $ellipsisizedDom.text(ellipsisizedText);

            $ellipsisizedDom
                .append($fadeHelperTmpl, $prefixTmpl, $moreCtrlTmpl, $lessCtrlTmpl)
                .addClass('ellipsisized ellipsisizedCollapsed');

            $moreCtrlTmpl.on('click', function () {
                var $etalonDomClone = $etalonDom.clone().addClass('ellipsisized');
                $etalonDomClone
                    .append($lessCtrlTmpl)
                    .removeClass('ellipsisizedCollapsed');
                var clone = $etalonDomClone.clone(true);
                $this.replaceWith(clone);
                $this = clone;
                if (cb){
                    cb(true);
                }
            });

            $lessCtrlTmpl.on('click', function () {
                var clone = $ellipsisizedDom.clone(true).addClass('ellipsisizedCollapsed');
                $this.replaceWith(clone);
                $this = clone;
                if (cb){
                    cb(false);
                }
            });

            var clone = $ellipsisizedDom.clone(true);
            $this.replaceWith(clone);
            $this = clone;
        }
    });
};

$.fn.tips = function () {
    return this.each(function () {
        var _self = this,
            olId = $(this).attr('id'),
            topOffset = -50;

        function init() {
            if ($('#' + olId).hasClass('started')) {
                return false;
            }

            $(_self).find('li').each(function (k, val) {
                var $parent = $('#' + $(this).attr('data-id')),
                    lastViewedTip = LAST_VIEWED_TIP ? parseInt(LAST_VIEWED_TIP) : 0;

                if ($parent.is(':visible') && !$parent.find('.firstVisitModal').is(':visible').length && parseInt($(this).attr('data-number')) >= lastViewedTip) {
                    $('.firstVisitModal').remove();
                    $(val).addClass('active');
                    appendTip($(val), k === 0 ? false : true);

                    return false;
                }
            })
        }

        function appendTip($li, isInit) {
            var k = 0,
                $parent = $('#' + $li.attr('data-id')),
                templateContainer = '<div class="firstVisitModal" data-index="' + k + '"></div>',
                html = $li.html(),
                $welcomeBanner = $('#welcomeBanner');

            var $addTip = $(templateContainer).appendTo($parent);
            $addTip.hide();
            $addTip.addClass($li.attr('data-class'));
            $addTip.append(html);
            $addTip.attr('data-index', k);
            $addTip.attr('data-related-parent', '#' + $li.attr('data-id'));

            $addTip.fadeIn();
            if (isInit) {
                $parent.scrollToFocused({topOffset: topOffset});
            }

            $.post(TRAINING_TIP_CLICKED_ENDPOINT, {
                lastViewedTip: $li.attr('data-number'),
                viewAll: $li.attr('data-number') >= $li.nextAll('li').filter(':last').attr('data-number') ? 1 : 0
            });

            $addTip.find('.firstVisitModalButton').on('touchstart click', function () {
                $('#' + olId).addClass('started');
                var $nextLi = $('#' + olId).find('li.active').next('li').eq(0),
                    $container = $(this).closest('.firstVisitModal');

                $('#' + olId).find('li.active').removeClass('active');

                if ($nextLi && $nextLi.length) {
                    $nextLi.addClass('active');
                    $nextTipBlock = appendTip($nextLi);
                } else {
                    $nextTipBlock = null;
                }

                $container.fadeOut();
                if ($nextTipBlock && $nextTipBlock.length) {
                    $($nextTipBlock.attr('data-related-parent')).scrollToFocused({topOffset: topOffset});
                    $nextTipBlock.fadeIn();
                } else {
                    $welcomeBanner.hide();
                    $.post(TRAINING_TIP_CLICKED_ENDPOINT, {
                        lastViewedTip: $li.attr('data-number'),
                        viewAll: 1
                    });
                }

                return false;
            });

            return $addTip;
        }

        init();


        return this;
    });

};

$.fn.formChangedAndValid = function (successClassName, errorClassName) {
    var
        _self = this,
        _elementSelector = _self.data('key-depend'),
        _formParent = _self.closest("form"),
        _errorClassName = errorClassName || 'disabled',
        _successClassName = successClassName || '',
        _requereElements = $('select[data-require="' + _elementSelector + '"],input[data-require="' + _elementSelector + '"]'),
        _orig = [];

    function storeOrigins() {
        _requereElements.each(function () {
            var type = $(this).getTypeEx();
            var tmp = {'type': type, 'value': $(this).val()};
            if (type === 'radio' || type === 'checkbox') {
                tmp.checked = $(this).is(':checked');
            }
            _orig[$(this).attr('id')] = tmp;
        });
    };

    var updateState = function () {
        var disable = true;
        _requereElements.each(function () {
            var type = $(this).getTypeEx();
            var id = $(this).attr('id');
            if (type === 'radio' || type === 'checkbox') {
                disable = (_orig[id].checked === $(this).is(':checked'));
            } else {
                disable = (_orig[id].value === $(this).val());
            }
            if (!disable) {
                return false;
            } // break out of loop
        });
        if (disable || !_formParent.valid())
            _self.removeClass(_successClassName).addClass(_errorClassName); // update button
        else
            _self.removeClass(_errorClassName).addClass(_successClassName);
    };
    _requereElements.on('change keyup', updateState);
    storeOrigins();
    _self.on('click', function () {
        return !_self.hasClass(_errorClassName);
    });

    // restore origin values
    _self.on('formChangedAndValid.reinit', function () {
        storeOrigins();
        updateState();
    });

    return this;
};
// check form if all field are edited
// check form if all field are edited
$.fn.formAllChangedAndValid = function (successClassName, errorClassName, checkByEvent) {
    var
        _checkByEvent = checkByEvent || 'change keyup blur',
        _self = this,
        _elementSelector = _self.data('key-depend'),
        _formParent = _self.closest("form"),
        _errorClassName = errorClassName || 'disabled',
        _successClassName = successClassName || '',
        _requereElements = $('select[data-require="' + _elementSelector + '"],input[data-require="' + _elementSelector + '"]'),
        _orig = [];

    function storeOrigins() {
        _requereElements.each(function () {
            var type = $(this).getTypeEx();
            var tmp = {'type': type, 'value': $(this).val()};
            if (type === 'radio' || type === 'checkbox') {
                tmp.checked = $(this).is(':checked');
            }
            _orig[$(this).attr('id')] = tmp;
        });
    };

    _requereElements.on(_checkByEvent, function () {
        var _validator = $(_formParent).validate(),
            disable = true,
            emptyRequiredField = false,
            allFieldsValid = true,
            fieldHasErrors = $(this).next('.error').length;

        if ($(this).getTypeEx() !== 'hidden') {
            if ($(this).val()) {
                if ((fieldHasErrors && !_validator.element($(this))) || !_validator.check($(this))) {
                    $(this).trigger('field-invalid');
                } else if (_validator.check($(this))) {
                    $(this).trigger('field-valid');
                }
            } else {
                $(this).trigger('field-invalid');
            }
        }
        _requereElements.each(function () {
            var type = $(this).getTypeEx();
            var id = $(this).attr('id');
            if (type === 'radio' || type === 'checkbox') {
                disable = (_orig[id].checked === $(this).is(':checked'));
            } else {
                if (disable)
                    disable = (_orig[id].value === $(this).val());

                emptyRequiredField = $(this).attr('data-can-be-empty') !== 'true' && !(_orig[id].value) && !$(this).val();
            }
            if (allFieldsValid && type !== 'hidden')
                allFieldsValid = _validator.check($(this));
        });
        if (emptyRequiredField || disable || allFieldsValid === false)
            _self.removeClass(_successClassName).addClass(_errorClassName).attr('disabled', true); // update button
        else
            _self.removeClass(_errorClassName).addClass(_successClassName).removeAttr('disabled');
    });
    storeOrigins();
    _self.on('click', function () {
        return !_self.hasClass(_errorClassName);
    });

    return this;
};
$.fn.formUpdateQuestions = function (successClassName, errorClassName, checkByEvent) {
    var
        _checkByEvent = checkByEvent || 'change keyup blur',
        _self = this,
        _elementSelector = _self.data('key-depend'),
        _formParent = _self.closest("form"),
        _errorClassName = errorClassName || 'disabled',
        _successClassName = successClassName || '',
        _questions = $('select[data-require="' + _elementSelector + '"]'),
        _answers = $('input[data-require="' + _elementSelector + '"][data-related-question]'),
        _required = $('[data-require="' + _elementSelector + '"]'),
        _origins = {};

    function storeOriginQuestions() {
        _questions.each(function () {
            _origins[$(this).attr('id')] = $(this).val();
        });
    }

    _questions.change(function() {
        var $this = $(this);

        $this.toggleClass('changed', $this.val() !== _origins[$this.attr('id')])
    });

    _required.on(_checkByEvent, function () {
        var
            _validator = $(_formParent).validate(),
            oneFieldValid = false,
            allFieldsValid = true;
        _answers.each(function () {
            var
                $this = $(this),
                fieldValidity,
                relatedQuestionId = $this.data('relatedQuestion'),
                $relatedQuestion = $('#' + relatedQuestionId, _formParent);

            // skip unchanged question
            if ($relatedQuestion.val() === _origins[relatedQuestionId] && $this.val().trim() === '') {
                return;
            }

            fieldValidity = _validator.check($this);
            oneFieldValid = oneFieldValid || fieldValidity;
            allFieldsValid = allFieldsValid && fieldValidity;
        });
        if (allFieldsValid === false || oneFieldValid === false)
            _self.removeClass(_successClassName).addClass(_errorClassName); // update button
        else
            _self.removeClass(_errorClassName).addClass(_successClassName);
    });
    storeOriginQuestions();
    _self.on('click', function () {
        return !_self.hasClass(_errorClassName);
    });

    return this;
};
if ($.validator) {
    $.validator.setDefaults({
        errorElement: "div"
    });
    $.validator.addMethod(
        'minlengthWithoutSpaces',
        function (value, element, length) {
            return this.optional(element) || value.trim().length >= length;
        },
        'Incorrect value length.'
    );
    $.validator.addMethod(
        "passwordValid",
        function (value, element, username) {
            return /^(?=.*[A-Z])(?=.*\W)(?=.*[0-9])(?=.*[a-z]).{8,}$/.test($.trim(value)) && (username === '' || value.indexOf(username) === -1);
        },
        "Incorrect password format."
    );
    $.validator.addMethod(
        "consecutiveCharsValid",
        function compare(inputStr, element, consecutiveMax) {
          var currSeq = 0;
          var lastChar = inputStr[0].charCodeAt();
          for (var i = 0; i < inputStr.length; i++) {
            if (inputStr[i].charCodeAt() >= lastChar - 1 && inputStr[i].charCodeAt() <= lastChar + 1) {
              currSeq++;
              if (currSeq === consecutiveMax) {
                return false;
              }
            } else {
              currSeq = 1;
            }
            lastChar = inputStr[i].charCodeAt();
          }
          return true;
        },
        "Too many consecutive/identical characters, password is easy to guess"
    );
    $.validator.addMethod(
        "notEqualOldValue",
        function (value, element, $oldValue) {
            var oldValue = typeof $oldValue === 'object' ? $oldValue.val() : $oldValue;

            return value.indexOf(oldValue) === -1;
        },
        "Incorrect new value"
    );
    $.validator.addMethod(
        "email",
        function (value) {
            var re = /^[a-z0-9!#$%&'’*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'’*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/i;
            return re.test(value);
        },
        "Email is invalid"
    );
    $.validator.addMethod("zipcode", function (value, element) {
        return this.optional(element) || /^\d{5}(?:-\d{4})?$/.test(value);
    }, "Please provide a valid zipcode.");
}

$.fn.getTypeEx = function () {
    if (this[0].tagName === "INPUT")
        return $(this[0]).attr("type").toLowerCase();
    else
        return this[0].tagName.toLowerCase();
};

(function (context) {

    var enable = document.URL.indexOf('DEBUG') !== -1 && window.console !== undefined;

    if (enable) {
        context.DEBUG_MODE = true;
    }

    var logger = function (type, args) {

        if (!enable) {
            return;
        }

        if (typeof console[type].apply === 'function') {
            console[type].apply(console, args);
        }
    };

    var Debug = {
        info: function () {
            logger('info', arguments);
        },
        log: function () {
            logger('log', arguments);
        },
        warn: function () {
            logger('warn', arguments);
        },
        error: function () {
            logger('error', arguments);
        }
    };

    (context.Debug = Debug);

})(this);

function CheckForRepeat(startIndex, originalString, charToCheck) {
    var repeatCount = 1;
    for(var i = startIndex+1; i< password.length; i++) {
        if(originalString.charAt(i) == charToCheck) {
            repeatCount++;
        } else {
        return repeatCount;
        }
    }
    return repeatCount;
}


function supportAjaxUploadProgressEvents() {
    var xhr = new XMLHttpRequest();
    return !! (xhr && ('upload' in xhr) && ('onprogress' in xhr.upload));
};

$.fn.attachSpinner = function (customConfig) {
    var $el = this;

    if(customConfig === false) {
        $el.spin(false);

        return false;
    }

    var config = _.extend({
        lines: 13,
        length: 25,
        width: 3,
        radius: 16,
        corners: 1,
        rotate: 0,
        direction: 1,
        color: "#000",
        speed: 1,
        trail: 60,
        shadow: false,
        hwaccel: false,
        zIndex: 2e9, // The z-index (defaults to 2000000000)
        top: "30px",
        left: "auto"
    },  customConfig || {});

    $el.spin(config);
};

/*
 * jQuery Easing v1.3 - http://gsgd.co.uk/sandbox/jquery/easing/
 *
 * Uses the built in easing capabilities added In jQuery 1.1
 * to offer multiple easing options
 *
 * TERMS OF USE - jQuery Easing
 *
 * Open source under the BSD License.
 *
 * Copyright Â© 2008 George McGinley Smith
 * All rights reserved.
 *
 * Redistribution and use in source and binary forms, with or without modification,
 * are permitted provided that the following conditions are met:
 *
 * Redistributions of source code must retain the above copyright notice, this list of
 * conditions and the following disclaimer.
 * Redistributions in binary form must reproduce the above copyright notice, this list
 * of conditions and the following disclaimer in the documentation and/or other materials
 * provided with the distribution.
 *
 * Neither the name of the author nor the names of contributors may be used to endorse
 * or promote products derived from this software without specific prior written permission.
 *
 * THIS SOFTWARE IS PROVIDED BY THE COPYRIGHT HOLDERS AND CONTRIBUTORS "AS IS" AND ANY
 * EXPRESS OR IMPLIED WARRANTIES, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF
 * MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE ARE DISCLAIMED. IN NO EVENT SHALL THE
 *  COPYRIGHT OWNER OR CONTRIBUTORS BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL,
 *  EXEMPLARY, OR CONSEQUENTIAL DAMAGES (INCLUDING, BUT NOT LIMITED TO, PROCUREMENT OF SUBSTITUTE
 *  GOODS OR SERVICES; LOSS OF USE, DATA, OR PROFITS; OR BUSINESS INTERRUPTION) HOWEVER CAUSED
 * AND ON ANY THEORY OF LIABILITY, WHETHER IN CONTRACT, STRICT LIABILITY, OR TORT (INCLUDING
 *  NEGLIGENCE OR OTHERWISE) ARISING IN ANY WAY OUT OF THE USE OF THIS SOFTWARE, EVEN IF ADVISED
 * OF THE POSSIBILITY OF SUCH DAMAGE.
 *
 */

// t: current time, b: begInnIng value, c: change In value, d: duration
jQuery.easing['jswing'] = jQuery.easing['swing'];

jQuery.extend(jQuery.easing,
    {
        def: 'easeOutQuad',
        swing: function (x, t, b, c, d) {
            return jQuery.easing[jQuery.easing.def](x, t, b, c, d);
        },
        easeInQuad: function (x, t, b, c, d) {
            return c * (t /= d) * t + b;
        },
        easeOutQuad: function (x, t, b, c, d) {
            return -c * (t /= d) * (t - 2) + b;
        },
        easeInOutQuad: function (x, t, b, c, d) {
            if ((t /= d / 2) < 1)
                return c / 2 * t * t + b;
            return -c / 2 * ((--t) * (t - 2) - 1) + b;
        },
        easeInCubic: function (x, t, b, c, d) {
            return c * (t /= d) * t * t + b;
        },
        easeOutCubic: function (x, t, b, c, d) {
            return c * ((t = t / d - 1) * t * t + 1) + b;
        },
        easeInOutCubic: function (x, t, b, c, d) {
            if ((t /= d / 2) < 1)
                return c / 2 * t * t * t + b;
            return c / 2 * ((t -= 2) * t * t + 2) + b;
        },
        easeInQuart: function (x, t, b, c, d) {
            return c * (t /= d) * t * t * t + b;
        },
        easeOutQuart: function (x, t, b, c, d) {
            return -c * ((t = t / d - 1) * t * t * t - 1) + b;
        },
        easeInOutQuart: function (x, t, b, c, d) {
            if ((t /= d / 2) < 1)
                return c / 2 * t * t * t * t + b;
            return -c / 2 * ((t -= 2) * t * t * t - 2) + b;
        },
        easeInQuint: function (x, t, b, c, d) {
            return c * (t /= d) * t * t * t * t + b;
        },
        easeOutQuint: function (x, t, b, c, d) {
            return c * ((t = t / d - 1) * t * t * t * t + 1) + b;
        },
        easeInOutQuint: function (x, t, b, c, d) {
            if ((t /= d / 2) < 1)
                return c / 2 * t * t * t * t * t + b;
            return c / 2 * ((t -= 2) * t * t * t * t + 2) + b;
        },
        easeInSine: function (x, t, b, c, d) {
            return -c * Math.cos(t / d * (Math.PI / 2)) + c + b;
        },
        easeOutSine: function (x, t, b, c, d) {
            return c * Math.sin(t / d * (Math.PI / 2)) + b;
        },
        easeInOutSine: function (x, t, b, c, d) {
            return -c / 2 * (Math.cos(Math.PI * t / d) - 1) + b;
        },
        easeInExpo: function (x, t, b, c, d) {
            return (t === 0) ? b : c * Math.pow(2, 10 * (t / d - 1)) + b;
        },
        easeOutExpo: function (x, t, b, c, d) {
            return (t === d) ? b + c : c * (-Math.pow(2, -10 * t / d) + 1) + b;
        },
        easeInOutExpo: function (x, t, b, c, d) {
            if (t === 0)
                return b;
            if (t === d)
                return b + c;
            if ((t /= d / 2) < 1)
                return c / 2 * Math.pow(2, 10 * (t - 1)) + b;
            return c / 2 * (-Math.pow(2, -10 * --t) + 2) + b;
        },
        easeInCirc: function (x, t, b, c, d) {
            return -c * (Math.sqrt(1 - (t /= d) * t) - 1) + b;
        },
        easeOutCirc: function (x, t, b, c, d) {
            return c * Math.sqrt(1 - (t = t / d - 1) * t) + b;
        },
        easeInOutCirc: function (x, t, b, c, d) {
            if ((t /= d / 2) < 1)
                return -c / 2 * (Math.sqrt(1 - t * t) - 1) + b;
            return c / 2 * (Math.sqrt(1 - (t -= 2) * t) + 1) + b;
        },
        easeInElastic: function (x, t, b, c, d) {
            var s;
            var p;
            var a = c;
            if (t === 0)
                return b;
            if ((t /= d) === 1)
                return b + c;

            p = d * .3;
            if (a < Math.abs(c)) {
                a = c;
                s = p / 4;
            }
            else
                s = p / (2 * Math.PI) * Math.asin(c / a);
            return -(a * Math.pow(2, 10 * (t -= 1)) * Math.sin((t * d - s) * (2 * Math.PI) / p)) + b;
        },
        easeOutElastic: function (x, t, b, c, d) {
            var s;
            var p;
            var a = c;
            if (t === 0)
                return b;
            if ((t /= d) === 1)
                return b + c;

            p = d * .3;
            if (a < Math.abs(c)) {
                a = c;
                s = p / 4;
            }
            else
                s = p / (2 * Math.PI) * Math.asin(c / a);
            return a * Math.pow(2, -10 * t) * Math.sin((t * d - s) * (2 * Math.PI) / p) + c + b;
        },
        easeInOutElastic: function (x, t, b, c, d) {
            var s;
            var p;
            var a = c;
            if (t === 0)
                return b;
            if ((t /= d / 2) === 2)
                return b + c;

            p = d * (.3 * 1.5);
            if (a < Math.abs(c)) {
                a = c;
                s = p / 4;
            }
            else
                s = p / (2 * Math.PI) * Math.asin(c / a);
            if (t < 1)
                return -.5 * (a * Math.pow(2, 10 * (t -= 1)) * Math.sin((t * d - s) * (2 * Math.PI) / p)) + b;
            return a * Math.pow(2, -10 * (t -= 1)) * Math.sin((t * d - s) * (2 * Math.PI) / p) * .5 + c + b;
        },
        easeInBack: function (x, t, b, c, d, s) {
            if (s === undefined)
                s = 1.70158;
            return c * (t /= d) * t * ((s + 1) * t - s) + b;
        },
        easeOutBack: function (x, t, b, c, d, s) {
            if (s === undefined)
                s = 1.70158;
            return c * ((t = t / d - 1) * t * ((s + 1) * t + s) + 1) + b;
        },
        easeInOutBack: function (x, t, b, c, d, s) {
            if (s === undefined)
                s = 1.70158;
            if ((t /= d / 2) < 1)
                return c / 2 * (t * t * (((s *= (1.525)) + 1) * t - s)) + b;
            return c / 2 * ((t -= 2) * t * (((s *= (1.525)) + 1) * t + s) + 2) + b;
        },
        easeInBounce: function (x, t, b, c, d) {
            return c - jQuery.easing.easeOutBounce(x, d - t, 0, c, d) + b;
        },
        easeOutBounce: function (x, t, b, c, d) {
            if ((t /= d) < (1 / 2.75)) {
                return c * (7.5625 * t * t) + b;
            } else if (t < (2 / 2.75)) {
                return c * (7.5625 * (t -= (1.5 / 2.75)) * t + .75) + b;
            } else if (t < (2.5 / 2.75)) {
                return c * (7.5625 * (t -= (2.25 / 2.75)) * t + .9375) + b;
            } else {
                return c * (7.5625 * (t -= (2.625 / 2.75)) * t + .984375) + b;
            }
        },
        easeInOutBounce: function (x, t, b, c, d) {
            if (t < d / 2)
                return jQuery.easing.easeInBounce(x, t * 2, 0, c, d) * .5 + b;
            return jQuery.easing.easeOutBounce(x, t * 2 - d, 0, c, d) * .5 + c * .5 + b;
        }
    });

/*
 *
 * TERMS OF USE - EASING EQUATIONS
 *
 * Open source under the BSD License.
 *
 * Copyright Â© 2001 Robert Penner
 * All rights reserved.
 *
 * Redistribution and use in source and binary forms, with or without modification,
 * are permitted provided that the following conditions are met:
 *
 * Redistributions of source code must retain the above copyright notice, this list of
 * conditions and the following disclaimer.
 * Redistributions in binary form must reproduce the above copyright notice, this list
 * of conditions and the following disclaimer in the documentation and/or other materials
 * provided with the distribution.
 *
 * Neither the name of the author nor the names of contributors may be used to endorse
 * or promote products derived from this software without specific prior written permission.
 *
 * THIS SOFTWARE IS PROVIDED BY THE COPYRIGHT HOLDERS AND CONTRIBUTORS "AS IS" AND ANY
 * EXPRESS OR IMPLIED WARRANTIES, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF
 * MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE ARE DISCLAIMED. IN NO EVENT SHALL THE
 *  COPYRIGHT OWNER OR CONTRIBUTORS BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL,
 *  EXEMPLARY, OR CONSEQUENTIAL DAMAGES (INCLUDING, BUT NOT LIMITED TO, PROCUREMENT OF SUBSTITUTE
 *  GOODS OR SERVICES; LOSS OF USE, DATA, OR PROFITS; OR BUSINESS INTERRUPTION) HOWEVER CAUSED
 * AND ON ANY THEORY OF LIABILITY, WHETHER IN CONTRACT, STRICT LIABILITY, OR TORT (INCLUDING
 *  NEGLIGENCE OR OTHERWISE) ARISING IN ANY WAY OUT OF THE USE OF THIS SOFTWARE, EVEN IF ADVISED
 * OF THE POSSIBILITY OF SUCH DAMAGE.
 *
 */



// https://bitbucket.org/accursoft/caret/src
(function($) {
  $.fn.caret = function(pos) {
    var target = this[0];
    var isContentEditable = target && target.contentEditable === 'true';
    if (arguments.length == 0) {
      //get
      if (target) {
        //HTML5
        if (window.getSelection) {
          //contenteditable
          if (isContentEditable) {
            target.focus();
            var range1 = window.getSelection().getRangeAt(0),
                range2 = range1.cloneRange();
            range2.selectNodeContents(target);
            range2.setEnd(range1.endContainer, range1.endOffset);
            return range2.toString().length;
          }
          //textarea
          return target.selectionStart;
        }
        //IE<9
        if (document.selection) {
          target.focus();
          //contenteditable
          if (isContentEditable) {
              var range1 = document.selection.createRange(),
                  range2 = document.body.createTextRange();
              range2.moveToElementText(target);
              range2.setEndPoint('EndToEnd', range1);
              return range2.text.length;
          }
          //textarea
          var pos = 0,
              range = target.createTextRange(),
              range2 = document.selection.createRange().duplicate(),
              bookmark = range2.getBookmark();
          range.moveToBookmark(bookmark);
          while (range.moveStart('character', -1) !== 0) pos++;
          return pos;
        }
        // Addition for jsdom support
        if (target.selectionStart)
          return target.selectionStart;
      }
      //not supported
      return;
    }
    //set
    if (target) {
      if (pos == -1)
        pos = this[isContentEditable? 'text' : 'val']().length;
      //HTML5
      if (window.getSelection) {
        //contenteditable
        if (isContentEditable) {
          target.focus();
          window.getSelection().collapse(target.firstChild, pos);
        }
        //textarea
        else
          target.setSelectionRange(pos, pos);
      }
      //IE<9
      else if (document.body.createTextRange) {
        if (isContentEditable) {
          var range = document.body.createTextRange();
          range.moveToElementText(target);
          range.moveStart('character', pos);
          range.collapse(true);
          range.select();
        } else {
          var range = target.createTextRange();
          range.move('character', pos);
          range.select();
        }
      }
      if (!isContentEditable)
        target.focus();
    }
    return this;
  }
})(jQuery);


function startVirtualCoaching(id) {

    var href = '/vitality/healthwise/conversation?id=' + id;


    $.fancybox.open([
        {
            href: href,
            type: 'iframe',
            autoResize: false,
            autoSize: false,
            minWidth: 516,
            width: 516,
            height: 'auto',
            minHeight: 398,
        }
    ], {
        padding: 0
    });
}

function isLanguageFrCa() {
    var frCaManulifeEndpoint = "${app_config['french-ca.manulife.site.domain']}";
    return location.href.indexOf(frCaManulifeEndpoint) !== -1;
}

function handleFaqModule ($container) {
    var $questions = $container.find('dd');

    $.each($questions, function() {
        var
            CUTOFF_RANGE = 200,
            $this = $(this),
            originalText = $this.text().trim(),
            originalDom,
            $textWrapper;

        function sliceHtml(html, text, range) {
            var
                result = '',
                specialLength = 0,
                textIndex = 0,
                tags = [],
                htmlEntity = '',
                htmlEntityLength;

            while (textIndex < range) {
                htmlEntity = '';
                while (html[textIndex + specialLength] === '<') {
                    while (html[textIndex + specialLength] !== '>') {
                        htmlEntity += html[textIndex + specialLength++];
                    }
                    htmlEntity += html[textIndex + specialLength++];
                    if (htmlEntity[1] !== '/') {
                        tags.push(htmlEntity);
                    } else {
                        tags.pop();
                    }
                }
                while (html[textIndex + specialLength] === '&') {
                    while (html[textIndex + specialLength] !== ';') {
                        htmlEntity += html[textIndex + specialLength++];
                    }
                    htmlEntity += html[textIndex + specialLength++];
                    htmlEntityLength = $('<span/>').html(htmlEntity).text().length
                    specialLength -= htmlEntityLength;
                    textIndex += htmlEntityLength;
                }
                result += htmlEntity + html[textIndex + specialLength];
                textIndex++;
            }

            tags = tags.filter(function(tag){return tag != '<br>'});
            _.each(tags, function(tag) {
                var matchResult = tag.match( /\<([^\s|\>]+)/i);
                var closing = [tag[0], '/', tag.slice(1)].join('');

                if(matchResult && matchResult[1]) {
                    closing = '</' + matchResult[1] + '>';
                }

                result += closing;
            });

            return result;
        }

        $this.wrapInner('<span class="text"></span>')
        $textWrapper = $this.find('span.text');
        originalDom = $textWrapper.html();

        if (originalText.length > CUTOFF_RANGE) {
            var
                ellipsisize,
                ellipsisized = false,
                cutoffPoint = CUTOFF_RANGE + originalText.substring(CUTOFF_RANGE).indexOf(' '),
                slicedHtml = sliceHtml(originalDom, originalText, cutoffPoint)  + '...&nbsp;&nbsp;&nbsp;',
                $toggleCtrl = $('<span role="button" class="toggleCtrl" aria-live="polite" tabindex="0"/>');

            (ellipsisize = function() {
                var
                    text = !ellipsisized ? slicedHtml : originalDom + '&nbsp;',
                    buttonText = ellipsisized ? 'Less' : 'More';

                ellipsisized ? $textWrapper.addClass('expanded') : $textWrapper.removeClass('expanded');
                $textWrapper.html(text);
                $toggleCtrl.text(buttonText);

                ellipsisized = !ellipsisized;
            })();

            $this.append($toggleCtrl);
            $toggleCtrl.click(ellipsisize);
            $toggleCtrl.on('keyup', function(e) {
                if (e.which === 13 || e.which === 32) {
                    ellipsisize();
                }
            })
        }
    });

    if ($questions.length > 3) {
        var
            toggleQuestions,
            $toggleable = $questions.slice(3),
            $toggleCtrl = $('<span role="button" class="toggleCtrl" aria-live="polite" tabindex="0"/>'),
            shown = true;

        (toggleQuestions = function(e) {
            var
                text = shown ? 'See all questions' : 'See fewer questions',
                action = shown ? 'hide' : 'show';


            e && (action === 'show') && $questions.find(tabActiveElementsList).filter(':visible:last').focus();

            $toggleCtrl.text(text);
            $toggleable[action]().prev()[action]();

            e && (action === 'hide') && $questions.find(tabActiveElementsList).filter(':visible:last').focus();

            shown = !shown;
        })();

        $container.append($toggleCtrl);
        $toggleCtrl.click(toggleQuestions);
        $toggleCtrl.on('keyup', function(e) {
            if (e.which === 13 || e.which === 32) {
                toggleQuestions();
            }
        })
    }
}

var AODA = {
    focusFirstInputInForm: function (form) {
        var $form = form instanceof jQuery ? form : $(form);
        $form.find('button, a, input:not([type="hidden"]), select, textarea, [tabindex]:not([tabindex="-1"])').eq(0).focus();
    }
};

var ARIA = {
    liveUpdate: function (el) {
        var $el;
        if (el) {
            $el = form instanceof jQuery ? el : $(el);
        }
        else {
            $el = $('.hiddenAria:last');
        }
        if ($el && $el.length) {
            var $spotMeta = $('<meta name="aria-spot" content="">');
            $el.before($spotMeta);
            var $bufferDode = $('<div></div>').append($el);
            $.when($.ready).then(function(_$el, _$spotMeta) {
                setTimeout(function(){
                    _$el.removeClass('hidden');
                    _$spotMeta.before(_$el);
                }, 1);
            }.bind(this, $el, $spotMeta));
        }
    }
};

Object.defineProperty(HTMLMediaElement.prototype, 'playing', {
    get: function(){
        return !! (this.currentTime > 0 && ! this.paused && ! this.ended && this.readyState > 2);
    }
});
